import React, { Component } from 'react';

import {
  Grid,
  Typography
} from '@material-ui/core';

import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import { globalColors } from '../styles/style-constants';


class ResourceCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      highlighted: false
    }
  }

  render() {
    const { classes, image, title, tagText, tagColor = globalColors.darkPink, description } = this.props;
    const { highlighted } = this.state;

    return (
      <Grid
        onMouseEnter={() => this.setState({ highlighted: true })}
        onMouseLeave={() => this.setState({ highlighted: false })} 
        container 
        direction="column" 
        justify="flex-start" 
        className={classNames(classes.wrapper, highlighted ? classes.selected : null)}
      >
        { image && (
          <img alt="img" className={classes.responsiveImg} src={image} />
        )}

        <Grid item>
          <span className={classes.tag} style={{ color: tagColor, borderColor: tagColor }}>{tagText}</span>
        </Grid>
        <Typography variant="h4" className={classes.inputLabel}>{title}</Typography>
        <Typography className={classes.label1}>{description}</Typography>
      </Grid>
    )
  }
};

const styles = theme => ({
  formControl: {
    width: '100%'
  },
  wrapper: {
    cursor: 'pointer',
    padding: 16,
    transition: '0.5s'
  },
  selected: {
    backgroundColor: theme.globalColors.white,
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
  },
  responsiveImg: {
    maxWidth: '100%',
    marginBottom: 24
  },
  tag: {
    padding: '8px 24px',
    borderRadius: '20px',
    border: '1px solid black',
    ...theme.typography.label1
  },
  inputLabel: {
    marginTop: 24,
    color: globalColors.blue
  },
  label1: {
    marginTop: 16,
    ...theme.typography.label1
  },
  body1: theme.typography.body1,
  input: theme.form.input
});

export default withStyles(styles)(ResourceCard);

import classNames from 'classnames';
import _ from 'lodash';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import { Component } from 'react';

import config from '../../config';

import FormHeader from '../FormHeader';
import InputField from '../InputField';
import Button from '../Button';

import {
  Grid,
  // Typography
} from '@material-ui/core';

import { globalStyles } from '../../styles/style-constants';

import {
  AuthenticationDetails,
  CognitoUser,
	CognitoUserPool
} from 'amazon-cognito-identity-js';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: ''
    };
  }

  login() {
    var authenticationData = {
      Username: 'm.huneke@gmail.com', // this.state.email,
      Password: 'M!kehun3k3123', // this.state.password,
    };

    var authenticationDetails = new AuthenticationDetails(
      authenticationData
    );

    const {
      UserPoolId,
      ClientId
    } = config;

    const { userStore } = this.props;
    const { email } = this.state;

    var userPool = new CognitoUserPool({
      UserPoolId,
      ClientId
    });
    var userData = {
      Username: this.state.email,
      Pool: userPool,
    };
    var cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async function(result) {
        var accessToken = result.getAccessToken().getJwtToken();
        await userStore.login(accessToken, email);
        window.location.href = "/";
      },

      onFailure: function(err) {
        alert(err.message || JSON.stringify(err));
      },
    });
  }

  forgotPassword() {

    if (_.isEmpty(this.state.email)) {
      return alert('Please enter a valid email address.');
    }

    const {
      UserPoolId,
      ClientId
    } = config;

    var userPool = new CognitoUserPool({
      UserPoolId,
      ClientId
    });

    var userData = {
      Username: this.state.email,
      Pool: userPool,
    };
    var cognitoUser = new CognitoUser(userData);
    
    cognitoUser.forgotPassword({
      onSuccess: function(data) {
        // successfully initiated reset password request
        alert('An email has been sent to reset your password.');
      },
      onFailure: function(err) {
        alert('There was an error resetting your password. Please contact the administrator.');
        alert(err.message || JSON.stringify(err));
      }
    });
  }

  render() {
    const { classes } = this.props;
    const { email, password } = this.state;

    return (
      <div className={classes.patternWrapper}>
        <Grid className={classNames(classes.widthWrapper, classes.panel)} direction="column" justify="center" alignItems="center">
          <Grid item xs={12}>
            <Grid container spacing={3} alignItems="center" justify="center" className={classes.content}> 
              <Grid item>
                <Grid direction="column" spacing={3} container alignItems="center" justify="center">
                  <Grid item>
                    <FormHeader text="Login" />
                  </Grid>
                  <Grid item style={{ marginTop: -24 }}>
                    <Grid container spacing={3} direction="row">
                      <Grid item className={classes.nameInput}>
                        <InputField collapsed={true} labelText={"Email Address"}
                          onChange={e => this.setState({ email: e.target.value })}
                          inputProps={{
                            value: email
                          }}
                        />
                      </Grid>
                      <Grid item className={classes.nameInput}>
                        <InputField collapsed={true} labelText={"Password"} 
                          onChange={e => this.setState({ password: e.target.value })}
                          inputProps={{
                            type: 'password',
                            value: password
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={1} direction="column" justify="space-between" alignItems="center">
                      <Grid item>
                        <Button onClick={this.login.bind(this)}>
                        <span>Log in</span>
                        </Button>
                      </Grid>
                      <Grid item>
                        <br/>
                        <br/>
                        <Grid container justify="space-between">
                          <Grid item style={{ marginRight: 80 }}>
                            <button onClick={this.forgotPassword.bind(this)}>Forgot Password</button>
                          </Grid>
                          <Grid item>
                            <a href="/register">Register</a>
                          </Grid>
                        </Grid>
                        
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const styles = theme => ({
  content: {
    minHeight: 'calc(100vh - 120px)'
  },
  patternWrapper: {
    height: '100vh',
    position: 'relative',
    zIndex: 1,
    backgroundColor: theme.globalColors.lightestGrey,
    '&:before': {  
      content: '""',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundImage: 'url(https://sunstone-storage-public.s3.amazonaws.com/website-resources/Vector.png)',
      position: 'absolute',
      opacity: 0.25,
      zIndex: -1
    }
  },
  widthWrapper: globalStyles.widthWrapper(theme)
});

export default withRouter(withStyles(styles)(inject('userStore')(observer(Login))));

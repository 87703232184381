import { makeObservable, observable } from 'mobx';
import _ from 'lodash';
import moment from 'moment';

import Api from '../api';
import SuperuserApi from '../superuser-api';

const humanizeDuration = require("humanize-duration");

class TrialStore {
  currentTrial = null;
  loadingTrial = true;
  loadingTrials = true;
  creatingTrial = false;

  trials = [];

  constructor() {
    makeObservable(this, {
      currentTrial: observable,
      loadingTrial: observable,
      loadingTrials: observable,
      creatingTrial: observable,
      trials: observable
    });
  }

  setCurrentTrial(trial) {
    this.currentTrial = trial;
    this.loadingTrial = false;
  }

  async fetchCurrentTrial(force = false) {
    if (this.currentTrial !== null && !force) {
      return;
    }

    try { 
      const { data } = await Api.getCurrentTrial();
      if (data.length > 0) {
        console.log(data[0])
        this.setCurrentTrial(data[0]);
      }
    } catch (e) {
      this.loadingTrial = false;
    }
  }

  async fetchAllTrials() {
    try { 
      this.loadingTrials = true;
      const { data } = await SuperuserApi.getAllTrials();
      this.trials = data;
      this.loadingTrials = false;
    } catch (e) {
      console.log(e);
      this.loadingTrials = false;
    }
  }

  async fetchTrial(trialId) {
    try { 
      this.loadingTrial = true;
      const { data } = await SuperuserApi.getTrial(trialId);
      this.currentTrial = data;
      this.loadingTrial = false;
    } catch (e) {
      console.log(e);
      this.loadingTrial = false;
    }
  }

  async createTrialEvent(event, api = Api) {
    await api.createTrialEvent(this.currentTrial.id, event);
  }

  async createTrial(trial) {
    try { 
      this.creatingTrial = true;
      await SuperuserApi.createTrial(trial);
      this.creatingTrial = false;
    } catch (e) {
      this.creatingTrial = false;
    }
  }

  async markEventComplete(trialEventId, event, extra = {}, status = 'complete') {
    await Api.createTrialUserProgress({
      trialEventId,
      status,
      extra
    });
  }

  async deleteTrialUserProgress(progressId) {
    await Api.deleteTrialUserProgress(progressId);
  }

  async deleteTrialUserEnrollment(trialId, enrollmentId) {
    await SuperuserApi.removeTrialEnrollment(trialId, enrollmentId);
  }

  async createTrialEnrollments(trialId, enrollments) {
    await SuperuserApi.createTrialEnrollments(trialId, enrollments);
  }

  get trialName() {
    return _.get(this.currentTrial, 'name', '');
  }

  get trialIntroLabel() {
    const currentTime = new Date().getTime();

    const trialDay = _.get(this.currentTrial, 'enrollment.extra.startDate', '');
    if (trialDay !== '') {
      const trialTime = moment(trialDay).toDate().getTime();

      if (trialTime > currentTime) {
        return `Your session is in ${humanizeDuration(trialTime - currentTime, { largest: 2, delimiter: " and " })}.`
      }
    }

    return '';
  }

  get trialDay() {
    let trialDay = _.get(this.currentTrial, 'enrollment.extra.startDate', '');
    console.log(trialDay);
    if (trialDay === '') {
      return moment(this.currentTrial.startDate).format('MMMM D, YYYY')
    }

    return moment(trialDay).format('MMMM D, YYYY');
  }

  get trialLocations() {
    const { Sites: sites = [] } = this.currentTrial;
    if (sites.length > 0) {
      return sites.map(s => s.name);
    }
    return [];
  }

  get therapistsName() {
    const { TrialRoles: users = [] } = this.currentTrial;
    const therapists = users.filter(u => u.role === 'therapist').map(u => u.User);

    return therapists.map(t => {
      return `${t.firstName} ${t.lastName}`;
    });
  }

  get doctorsNames() {
    const { TrialRoles: users = [] } = this.currentTrial;
    const doctors = users.filter(u => u.role === 'doctor').map(u => u.User);

    return doctors.map(t => {
      return `${t.firstName} ${t.lastName}`;
    });
  }

  get nextEvent() {
    if (!this.currentTrial) {
      return null;
    }

    for (let i = 0; i < this.currentTrial.TrialEvents.length; i++) {
      const te = this.currentTrial.TrialEvents[i];
      if (moment(te.startDate).isAfter(moment())) {
        return te;
      }
    }

    return null;
  }

  get trialEvents() {
    if (!this.currentTrial) {
      return [];
    }

    return this.currentTrial.TrialEvents.map(event => ({
      completed: event.TrialUserProgresses.find(tup => tup.status === 'complete') != null,
      ...event,
      title: event.name
    }));
  }

  get currentTrialParticipants() {
    if (!this.currentTrial) {
      return [];
    }

    return this.currentTrial.TrialRoles.filter(u => u.role === 'participant').map(tr => ({
      enrollmentId: tr.id,
      ...tr.User
    }));
  }

  get upcomingTrials() {
    return this.trials.filter(t => moment(t.endDate).isAfter(moment()));
  }

  get pastTrials() {
    return this.trials.filter(t => moment(t.endDate).isBefore(moment()));
  }
}

export default new TrialStore();
import React from 'react';
import classNames from 'classnames';

import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import { globalColors, globalStyles } from './styles/style-constants';

import Loading from './components/core/Loading';

import Button from './components/Button';
import InputField from './components/InputField';

import {
  Grid
} from '@material-ui/core';

import ApplicationMenu from './components/ApplicationMenu';
import ApplicationHeader from './components/ApplicationHeader';
import Header from './components/trial/common/Header';

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  componentDidMount() {
    this.props.userStore.fetchUserProfile();

    this.setState({
      ...this.props.userStore.user
    });
  }

  async updateProfile() {
    const { id, email, firstName, lastName } = this.state;

    await this.props.userStore.updateUserProfile({
      id,
      email,
      firstName,
      lastName
    });

    alert('Profile updated.');
  }
  
  render() {
    const { classes } = this.props;
    const { userStore } = this.props;

    if (!userStore.user) {
      return <Loading />;
    }

    const { firstName, lastName, email } = this.state;

    return (
      <Grid>
        <ApplicationMenu />
        <Grid container spacing={3} justify="flex-end" direction="column" className={classNames(classes.teamWrapper)}>
          <Grid item>
            <ApplicationHeader />
          </Grid>
          <Grid item>
            <Header 
              title={`Profile`} />
          </Grid>
        </Grid>

        <Grid className={classNames(classes.widthWrapper, classes.panel)} direction="column" justify="center" alignItems="center">
          <Grid item xs={12}>
            <Grid container spacing={3} alignItems="center" direction="column" justify="center" className={classes.content}> 
              <Grid item>
                <Grid direction="column" spacing={3} container alignItems="center" justify="center">
                  <Grid item className={classes.nameInput}>
                    <InputField collapsed={false} labelText={"First Name"}
                      onChange={e => this.setState({ firstName: e.target.value })}
                      inputProps={{
                        value: firstName
                      }}
                    />
                  </Grid>
                  <Grid item className={classes.nameInput}>
                    <InputField collapsed={false} labelText={"Last Name"}
                      onChange={e => this.setState({ lastName: e.target.value })}
                      inputProps={{
                        value: lastName
                      }}
                    />
                  </Grid>
                  <Grid item className={classes.nameInput}>
                    <InputField collapsed={false} labelText={"Email Address"}
                      onChange={e => this.setState({ email: e.target.value })}
                      inputProps={{
                        value: email
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Button onClick={this.updateProfile.bind(this)}>
                  <span>Save</span>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

const styles = theme => ({
  eventWrapper: {
    marginTop: -24
  },
  teamWrapper: {
    background: globalColors.lightestGrey
  },
  widthWrapperFull: {
    width: '100%',
    ...globalStyles.widthWrapper(theme)
  },
});

export default withRouter(withStyles(styles)(inject('userStore')(observer(ProfilePage))));
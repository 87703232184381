import classNames from 'classnames';

import {
  Grid,
  OutlinedInput,
  FormControl,
  FormHelperText,
  Typography
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

function InputField({ classes, labelText, helperText = null, collapsed = false, ...rest }) {
  return (
    <Grid container direction="column" justify="flex-start" className={collapsed ? classes.wrapper : null}>
      { !collapsed && (
        <Grid item>
          <Typography className={classes.inputLabel}>{labelText}</Typography>
        </Grid>
      )}
      <Grid item xs>
        <FormControl className={classes.formControl}>
          <OutlinedInput placeholder={collapsed ? labelText : ''} className={classNames(classes.input, !collapsed ? classes.boxShadow : null)} id="my-input" aria-describedby="my-helper-text" style={{ backgroundColor: 'white' }} {...rest} />
          { helperText && (
            <FormHelperText id="my-helper-text">{helperText}</FormHelperText>
          )}
        </FormControl>
      </Grid>
    </Grid>
  )
}

const styles = theme => ({
  formControl: {
    width: '100%'
  },
  wrapper: {
    backgroundColor: 'transparent',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
    width: '100%'
  },
  boxShadow: {
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
  },
  inputLabel: theme.typography.label2,
  body1: theme.typography.body1,
  input: theme.form.input
});

export default withStyles(styles)(InputField);

import { makeObservable, observable, action } from 'mobx';
import Api from '../api';
import SuperuserApi from '../superuser-api';
import { clone } from 'lodash';

class UserStore {
  user = null;
  isFetchingUserProfile= true;
  isAuthenticated = true;
  isAdmin = true;
  ssoUrl = '';
  users = [];
  loadingUsers = false;
  loadingUser = false;
  currentUser = null;

  selectedUsers = [];

  constructor() {
    makeObservable(this, {
      isFetchingUserProfile: observable,
      isAuthenticated: observable,
      user: observable,
      users: observable,
      loadingUsers: observable,
      currentUser: observable,
      loadingUser: observable,
      selectedUsers: observable,
      selectUser: action
    });
  }

  setUserProfile(user) {
    this.isAuthenticated = true;
    this.user = user;
    this.isFetchingUserProfile = false;
  }

  get firstName() {
    if (!this.user) {
      return '';
    }

    return this.user.firstName;
  }

  get fullName() {
    if (!this.user) {
      return '';
    }

    return `${this.user.firstName} ${this.user.lastName}`;
  }

  get username() {
    if (!this.user) {
      return '';
    }

    return this.user.username;
  }

  get isSuperAdmin() {
    if (!this.user) {
      return false;
    }

    return this.user.role === 'superadmin';
  }

  get isParticipant() {
    if (!this.user) {
      return false;
    }

    return this.user.role === 'participant';
  }

  async register(profile) {
    try {
      await Api.register(profile);
    } catch (e) {
      console.log(e);
      // TODO
    }
  }

  async login(accessToken, email) {
    try {
      const { data } = await Api.login(accessToken, email);
      if (data !== undefined) {
        this.setUserProfile(data);
      }
    } catch (e) {
      console.log(e);
      // TODO
    }
  }

  async logout() {
    try {
      await Api.logout();
      this.isAuthenticated = false;
      this.user = null;
    } catch (e) {
      // TODO
    }
  }

  async fetchUserProfile(force = false, setFetching = true) {
    this.user = {
      id: 1,
      name: 'Test User'
    };
    this.isFetchingUserProfile = false;
    this.isAuthenticated = true;
    
    // if (this.user !== null && !force) {
    //   this.isFetchingUserProfile = false;
    //   return;
    // }

    // try {
    //   this.isFetchingUserProfile = setFetching;
    //   const { status, data } = await Api.getUserProfile();

    //   if (status === 401) {
    //     this.isAuthenticated = false;
    //     this.ssoUrl = data.ssoUrl;
    //   } else {
    //     if (data !== undefined) {
    //       this.setUserProfile(data);
    //     }
    //   }

    //   this.isFetchingUserProfile = false;

    //   return data;
    // }
    // catch (e) {
    //   this.isFetchingUserProfile = false;
    //   this.isAuthenticated = false;
    // }
  }

  async getUsers() {
    try {
      this.loadingUsers = true;
      const { data: users } = await SuperuserApi.getUsers();
      this.users = users;
      this.loadingUsers = false;
    } catch (e) {
      this.loadingUsers = false;
      console.log(e);
    }
  }

  async getUser(userId) {
    try {
      this.loadingUser = true;
      const { data: user } = await SuperuserApi.getUser(userId);
      console.log(user);
      this.currentUser = user;
      this.loadingUser = false;
    } catch (e) {
      this.loadingUser = false;
      console.log(e);
    }
  }

  async updateUser(user) {
    try {
      const { data } = await SuperuserApi.updateUser(user);
    } catch (e) {
      console.log(e);
    }
  }

  async createUser(user) {
    try {
      const { data } = await SuperuserApi.createUser(user);
    } catch (e) {
      console.log(e);
    }
  }

  get activeUsers() {
    return this.users.filter(u => u.status === 'active');
  }

  get invitations() {
    return this.users.filter(u => u.status === 'pending');
  }

  selectUser(user) {
    if (this.selectedUsers.map(s => s.id).indexOf(user.id) > -1) {
      this.selectedUsers = this.selectedUsers.filter(s => s.id !== user.id);
    } else {
      this.selectedUsers = this.selectedUsers.concat([user]);
    }
  }

  async updateUserProfile(updates) {
    try {
      await Api.updateUserProfile(updates);
      await this.fetchUserProfile(true, false);
    }
    catch (e) {
      console.log(e);
    }
  }
}

export default new UserStore();

import React from 'react';
import moment from 'moment';    
import classNames from 'classnames';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import InputField from '../InputField';

import { globalColors, globalStyles } from '../../styles/style-constants';

import Button from '../Button';
import TrialListing from './trials/TrialListing';

import {
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';

class TrialManager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      creatingTrial: false,
      newTrialDate: '',
      newTrialName: '',
      value: 0,
      queryParams: {

      }
    };
  }
  
  componentDidMount() {
    this.props.trialStore.fetchAllTrials();
  }

  submitNewTrial() {
    const { trialStore } = this.props;
    const { newTrialName: name, newTrialDate: date } = this.state;

    trialStore.createTrial({
      name,
      date: moment(date, 'MM/D/YYYY HH:MM').toDate()
    })
  }

  fetchTrialData() {
    const { trialStore } = this.props;
    const { queryParams } = this.state;

    trialStore.fetchAllTrials(queryParams);
  }

  onTrialListingTableChange(queryParams) {

  }

  handleChange(event, newValue) {
    this.setState({
      value: newValue
    })
  };

  render() {
    const { classes, /* userStore, */ trialStore: { upcomingTrials, pastTrials } } = this.props;

    const { newTrialName, newTrialDate, creatingTrial, value } = this.state;

    return (
      <Grid container spacing={3} justify="flex-end" direction="column" className={classNames(classes.teamWrapper)}>

        <Grid container direction="column" className={classNames(classes.headerWrapper)}>
          <Grid item className={classNames(classes.widthWrapperFull)}>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <Typography className={classes.h2}>All Trials</Typography>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item>
                    <Paper elevation={0} className={classes.root}>
                      <Tabs
                        value={value}
                        onChange={this.handleChange.bind(this)}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                      >
                        <Tab className={classNames(classes.label1, value === 1 ? classes.label1Inactive : null)} label="Upcoming Trials" />
                        <Tab className={classNames(classes.label1, value === 0 ? classes.label1Inactive : null)} label="Past Trials" />
                      </Tabs>
                    </Paper>
                  </Grid>
                </Grid>
                <Grid container style={{ paddingTop: 24, paddingBottom: 24 }} justify="space-between">
                  <Grid>
                    <InputField placeholder="Search for a trial" />
                  </Grid>
                  <Grid item>
                    <Button onClick={() => this.setState({ creatingTrial: true })}>
                      Add a trial
                    </Button>
                  </Grid>
                </Grid>

                { value === 0 && (
                  <TrialListing isLoading={false} data={upcomingTrials} totalRows={upcomingTrials.length} fetchData={this.onTrialListingTableChange.bind(this)} />
                )}
                { value === 1 && (
                  <TrialListing isLoading={false} data={pastTrials} totalRows={pastTrials.length} fetchData={this.onTrialListingTableChange.bind(this)} />
                )}
              </Grid>

              { creatingTrial && (
                <Grid item>
                  <Grid container>
                    <Grid item>
                      <InputField collapsed={true} labelText={"Trial Name"} 
                        onChange={e => this.setState({ newTrialName: e.target.value })}
                        inputProps={{
                          value: newTrialName
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <InputField collapsed={true} labelText={"Trial Date"}
                        onChange={e => this.setState({ newTrialDate: e.target.value })}
                        inputProps={{
                          value: newTrialDate
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Button onClick={this.submitNewTrial.bind(this)}>
                        Submit
                      </Button>
                    </Grid>

                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  eventWrapper: {
    marginTop: -24
  },
  teamWrapper: {
    background: globalColors.lightestGrey
  },
  widthWrapperFull: {
    width: '100%',
    ...globalStyles.widthWrapper(theme)
  },
  label1: {
    ...globalStyles.label1,
    padding: '24px 50px',
    minWidth: 220
  },
  label1Inactive: {
    background: globalColors.lightGrey
  },
  h2: {
    ...globalStyles.h2(theme),
    color: globalColors.blue
  }
});

export default withRouter(withStyles(styles)(inject('userStore', 'trialStore')(observer(TrialManager))));

/* eslint-disable no-unused-vars */

import React from 'react';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import { globalStyles, globalColors } from '../../../styles/style-constants';

const styles = theme => ({
  wrapper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    boxSizing: "border-box",
    background: globalColors.darkerGrey,
    height: 51
  },
  arrowWrapper: {
    width: "auto",
    fontSize: '10px',
    lineHeight: 1,
    marginTop: "-1px", // Need to debug this
    marginLeft: theme.spacing(1),
  },
  label1: {
    ...globalStyles.label1
  },
  arrow: {
    cursor: "pointer",
  },
  inactiveArrow: {
    opacity: 0.2,
  }
});

class HeaderCell extends React.PureComponent {
  constructor(props) {
    super(props);

    this.sortAsc = this.sortAsc.bind(this);
    this.sortDesc = this.sortDesc.bind(this);
  }

  sortAsc() {
    const {
      sortKey,
      sortingApplied,
      isAscOrder,
      onSortChange,
      onSortReset,
    } = this.props;

    if (sortingApplied && isAscOrder) {
      onSortReset();
    } else {
      onSortChange({
        sortKey,
        isAscOrder: true,
      });
    }
  }

  sortDesc() {
    const {
      sortKey,
      sortingApplied,
      isAscOrder,
      onSortChange,
      onSortReset,
    } = this.props;

    if (sortingApplied && !isAscOrder) {
      onSortReset();
    } else {
      onSortChange({
        sortKey,
        isAscOrder: false,
      });
    }
  }

  render() {
    const {
      classes,
      wrapperClass,
      style,
      title,
      sortKey,
      sortingApplied,
      isAscOrder,
      alignToEdge
    } = this.props;

    return (
      <Grid
        container
        justify={alignToEdge ? "flex-start" : "center"}
        alignItems="center"
        style={style}
        className={classNames(classes.wrapper, wrapperClass)}
      >
        <Grid item>
          <Typography className={classes.label1}>{title}</Typography>
        </Grid>
        {
          sortKey && (
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              className={classes.arrowWrapper}
            >
              <span
                className={classNames(classes.arrow, {[classes.inactiveArrow]: sortingApplied && !isAscOrder })}
                onClick={this.sortAsc}
              >
                ▲
              </span>
              <span
                className={classNames(classes.arrow, {[classes.inactiveArrow]: sortingApplied && isAscOrder })}
                onClick={this.sortDesc}
              >
                ▼
              </span>
            </Grid>
          ) 
        }
      </Grid>
    );
  }
}

export default withStyles(styles)(HeaderCell);

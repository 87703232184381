import React from "react";

import classNames from 'classnames';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';

import { globalColors, globalStyles } from './styles/style-constants';

import {
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';

import InputField from './components/InputField';

import InfoPanel from './components/trial/common/InfoPanel';
import Loading from './components/core/Loading';
import Button from './components/Button';
import CustomLink from './components/core/CustomLink';
import ApplicationHeader from './components/ApplicationHeader';
import ApplicationMenu from './components/ApplicationMenu';
import UserListing from './components/superuser/users/UserListing';

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      searchTerm: '',
      queryParams: {

      }
    };
  }

  componentDidMount() {
    this.props.userStore.getUsers();
  }

  onUserListingTableChange() {

  }

  handleChange(event, newValue) {
    this.setState({
      value: newValue
    })
  };

  async onUserRemoved(user) {
    if (window.confirm('This action cannot be undone. Continue?')) {
      await this.props.userStore.updateUser({
        id: user.id,
        status: 'inactive'
      });
      await this.props.userStore.getUsers();
    }
  }

  onEditUser(user) {

  }

  render() {
    const { classes, userStore, userStore: { loadingUsers, invitations, activeUsers } } = this.props;

    const { newTrialName, newTrialDate, creatingTrial, value, searchTerm } = this.state;

    if (loadingUsers) {
      return <Loading />
    }

    const applySearch = user => {
      if (isEmpty(searchTerm)) {
        return true;
      }

      return (
        user.firstName.toLowerCase().indexOf(searchTerm.toLowerCase()) === 0 ||
        user.lastName.toLowerCase().indexOf(searchTerm.toLowerCase()) === 0
      );
    }

    return (
      <Grid container spacing={3} justify="flex-end" direction="column" className={classNames(classes.teamWrapper)}>
        <ApplicationMenu />
        <Grid item>
          <ApplicationHeader />
        </Grid>

        <br/>
        <Grid container spacing={3} justify="flex-end" direction="column" className={classNames(classes.teamWrapper)}>

          <Grid container direction="column" className={classNames(classes.headerWrapper)}>
            <Grid item className={classNames(classes.widthWrapperFull)}>
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <Typography className={classes.h2}>Users</Typography>
                </Grid>
                <Grid item>
                  <Grid container>
                    <Grid item>
                      <Paper elevation={0} className={classes.root}>
                        <Tabs
                          value={value}
                          onChange={this.handleChange.bind(this)}
                          indicatorColor="primary"
                          textColor="primary"
                          centered
                        >
                          <Tab className={classNames(classes.label1, value === 1 ? classes.label1Inactive : null)} label="Active Users" />
                          <Tab className={classNames(classes.label1, value === 0 ? classes.label1Inactive : null)} label="Pending Invitations" />
                        </Tabs>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container style={{ paddingTop: 24, paddingBottom: 24 }} justify="space-between">
                    <Grid>
                      <InputField value={searchTerm} onChange={e => this.setState({ searchTerm: e.target.value })} placeholder="Search for a user" />
                    </Grid>
                    <Grid item>
                      <CustomLink to="/users/new">
                        <Button>
                          Add a user
                        </Button>
                      </CustomLink>
                    </Grid>
                  </Grid>

                  { value === 0 && (
                    <UserListing 
                      isLoading={loadingUsers} 
                      data={activeUsers.filter(applySearch)} 
                      totalRows={activeUsers.length} 
                      fetchData={this.onUserListingTableChange.bind(this)} 
                      onUserRemoved={this.onUserRemoved.bind(this)} 
                      onEditUser={this.onEditUser.bind(this)}
                    />
                  )}
                  { value === 1 && (
                    <UserListing 
                      isLoading={loadingUsers} 
                      data={invitations.filter(applySearch)} 
                      totalRows={invitations.length} 
                      fetchData={this.onUserListingTableChange.bind(this)} 
                      onUserRemoved={this.onUserRemoved.bind(this)} 
                      onEditUser={this.onEditUser.bind(this)}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
};

const styles = theme => ({
  eventWrapper: {
    marginTop: -24
  },
  teamWrapper: {
    background: globalColors.lightestGrey
  },
  widthWrapperFull: {
    width: '100%',
    ...globalStyles.widthWrapper(theme),
  },
  h2: {
    ...globalStyles.h2(theme),
    color: globalColors.blue
  },
  h3: {
    ...globalStyles.h3(theme),
  },
  label1: {
    ...globalStyles.label1,
    padding: '24px 50px',
    minWidth: 300
  },
  label1Inactive: {
    background: globalColors.lightGrey
  },
});

export default withRouter(withStyles(styles)(inject('userStore')(observer(Home))));

import React from 'react';
import moment from 'moment';

import classNames from 'classnames';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import { globalColors, globalStyles } from './styles/style-constants';

import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@material-ui/core';

import InfoPanel from './components/trial/common/InfoPanel';
import Loading from './components/core/Loading';
import Button from './components/Button';
import CustomLink from './components/core/CustomLink';
import ApplicationHeader from './components/ApplicationHeader';
import ApplicationMenu from './components/ApplicationMenu';
import ParticipantListing from './components/superuser/participants/ParticipantListing';
import UserListing from './components/superuser/users/UserListing';
import TrialCalendar from './components/trial/TrialCalendar';

import InputField from './components/InputField';
import DatePicker from './components/DatePicker';

import SuperuserApi from './modules/superuser-api';

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      openTrialEvent: false,
      newEvent: {

      }
    }
  }

  componentDidMount() {
    this.props.trialStore.fetchTrial(this.props.match.params.trialId);
  }

  onParticipantListingTableChange() {

  }

  async handleParticipantRemoved(participant) {
    if (window.confirm('This action cannot be undone. Continue?')) {
      await this.props.trialStore.deleteTrialUserEnrollment(this.props.trialStore.currentTrial.id, participant.enrollmentId);
      await this.props.trialStore.fetchTrial(this.props.trialStore.currentTrial.id);
      alert('Participant removed from trial.');
    }
  }

  selectUser(user) {
    this.props.userStore.selectUser(user);
  }

  async enrollUsers() {
    const { selectedUsers } = this.props.userStore;
    await this.props.trialStore.createTrialEnrollments(this.props.match.params.trialId, selectedUsers.map(user => ({
      role: 'participant',
      userId: user.id
    })));
    this.setState({ open: false });
  };

  handleClose() {
    this.setState({ open: false });
  }

  handleCloseTrialEvent() {
    this.setState({ openTrialEvent: false });
  }

  async createTrialEvent() {
    await this.props.trialStore.createTrialEvent(this.state.newEvent, SuperuserApi);
    await this.props.trialStore.fetchTrial(this.props.trialStore.currentTrial.id);
    this.setState({ openTrialEvent: false, newEvent: {} });
  }

  onSelectEvent(event) {
    console.log(event);
  }

  render() {
    const { classes, userStore: { selectUser, selectedUsers }, trialStore, trialStore: { currentTrial, currentTrialParticipants } } = this.props;
    const { open, newEvent, openTrialEvent } = this.state;

    if (!currentTrial) {
      return <Loading />
    }

    return (
      <Grid container spacing={3} justify="flex-end" direction="column" className={classNames(classes.teamWrapper)}>
        <ApplicationMenu />
        <Grid item>
          <ApplicationHeader />
        </Grid>

        <Grid container direction="column" className={classNames(classes.headerWrapper)}>
          <Grid item className={classNames(classes.widthWrapperFull)}>
            <Grid item>
              <CustomLink to="/">
                <Typography className={classes.buttonBack}>Back to all trials</Typography>
              </CustomLink>
            </Grid>

            <Grid item>
              <Grid container alignItems="center" justify="space-between" style={{marginTop: 32}}>
                <Grid item>
                  <Typography className={classes.h2}>{ currentTrial.name }</Typography>
                </Grid>
                <Grid item>
                  <Button style={{backgroundColor: 'white', border: `2px solid ${globalColors.blue}`}}><span style={{ color: globalColors.blue }}>Download the Protocol</span></Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item style={{ marginTop: 24 }}>
              <Grid container spacing={3}>
                <Grid item>
                  <InfoPanel
                    label={"Status"}
                    data={trialStore.currentTrial.status}
                    // icon={"icons/alert-circle.png"}
                  />
                </Grid>
                <Grid item>
                  <InfoPanel
                    label={"Trial Day"}
                    data={trialStore.trialDay}
                    // icon={"icons/calendar.png"}
                  />
                </Grid>
                <Grid item>
                  <InfoPanel
                    label={"Location"}
                    data={trialStore.trialLocations.map(site => (
                      <Typography className={classes.h4}>
                        {site}
                      </Typography>
                    ))}
                    // icon={"icons/map.png"}
                  />
                </Grid>
                <Grid item>
                  <InfoPanel
                    label={"Therapist(s)"}
                    data={trialStore.therapistsName.map(therapist => (
                      <Typography className={classes.h4}>
                        {therapist}
                      </Typography>
                    ))}
                    // icon={"icons/mail.png"}
                  />
                </Grid>
                <Grid item>
                  <InfoPanel
                    label={"Doctor(s)"}
                    data={trialStore.doctorsNames.map(doctor => (
                      <Typography className={classes.h4}>
                        {doctor}
                      </Typography>
                    ))}
                    // icon={"icons/mail.png"}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item style={{ marginTop: 40 }}>
              <Typography className={classes.h3}>Participants</Typography>
              <Grid item>
                <Grid container alignItems="center" justify="space-between" style={{marginTop: 0}}>
                  <Grid item>
                  </Grid>
                  <Grid item>
                    <Button onClick={() => this.setState({ open: true })}>Add Participant</Button>
                  </Grid>
                </Grid>
              </Grid>
              <br/>
              <ParticipantListing onParticipantRemoved={this.handleParticipantRemoved.bind(this)} isLoading={false} data={currentTrialParticipants} totalRows={currentTrialParticipants.length} fetchData={this.onParticipantListingTableChange.bind(this)} />
            </Grid>

            <Grid item style={{ marginTop: 72 }}>
              <Grid container justify="space-between">
                <Grid item>
                  <Typography className={classes.h3}>Calendar</Typography>
                </Grid>
                <Grid item>
                  <Button onClick={e => this.setState({ openTrialEvent: true })}>Add Trial Event</Button>
                </Grid>
              </Grid>
              
              <br/>
              <br/>
              <TrialCalendar onSelectEvent={this.onSelectEvent.bind(this)} trial={trialStore.currentTrial} />
            </Grid>
          </Grid>
        </Grid>

        <Dialog
          fullWidth
          maxWidth="lg"
          open={openTrialEvent}
          onClose={this.handleCloseTrialEvent.bind(this)}
          style={{zIndex: 1399}}
          scroll="body"
          classes={{
            paper: classes.dialogPaper
          }}
        >
          <DialogTitle disableTypography className={classes.dialogTitle}>
            <Grid container direction="row" alignItems="flex-start" justify="space-between">
              <Grid item>
                <Typography variant="h2">
                  Add Trial Event
                </Typography>
              </Grid>
              <Grid item>
                <IconButton className={classes.closeButton} onClick={this.handleCloseTrialEvent.bind(this)}>
                  &times;
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Grid container>
              <Grid container spacing={3} justify="center" direction="column">
                <Grid item>
                  <InputField collapsed={false} labelText={"Event Name"} 
                    onChange={e => this.setState(prevState => prevState.newEvent.name = e.target.value)}
                    inputProps={{
                      value: newEvent.name
                    }}
                  />
                </Grid>

                <Grid item>
                  <DatePicker collapsed={false} labelText={"Start Date/Time"} 
                    onChange={e => this.setState(prevState => prevState.newEvent.startDate = e)}
                    inputProps={{
                      value: newEvent.startDate
                    }}
                  />
                </Grid>

                <Grid item>
                  <DatePicker collapsed={false} labelText={"End Date/Time"} 
                    onChange={e => this.setState(prevState => prevState.newEvent.endDate = e)}
                    inputProps={{
                      value: newEvent.endDate
                    }}
                  />
                </Grid>                

              </Grid>
              <Grid item>
                <Button onClick={this.createTrialEvent.bind(this)}>Submit</Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog
          fullWidth
          maxWidth="lg"
          open={open}
          onClose={this.handleClose.bind(this)}
          style={{zIndex: 1399}}
          scroll="body"
          classes={{
            paper: classes.dialogPaper
          }}
        >
          <DialogTitle disableTypography className={classes.dialogTitle}>
            <Grid container direction="row" alignItems="flex-start" justify="space-between">
              <Grid item>
                <Typography variant="h2">
                  Add Trial Participants
                </Typography>
              </Grid>
              <Grid item>
                <IconButton className={classes.closeButton} onClick={this.handleClose.bind(this)}>
                  &times;
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Grid container>
              <UserListing onSelectUser={this.selectUser.bind(this)} isLoading={false} canSelectUsers={true} data={currentTrialParticipants} totalRows={currentTrialParticipants.length} fetchData={this.onParticipantListingTableChange.bind(this)} />
              <Grid item>
                <Button onClick={this.enrollUsers.bind(this)}>Submit</Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

      </Grid>
    );
  }
};

const styles = theme => ({
  eventWrapper: {
    marginTop: -24
  },
  dialogTitle: {
    padding: 24
  },
  teamWrapper: {
    background: globalColors.lightestGrey
  },
  widthWrapperFull: {
    width: '100%',
    ...globalStyles.widthWrapper(theme),
  },
  h2: {
    ...globalStyles.h2(theme),
    color: globalColors.blue
  },
  h3: {
    ...globalStyles.h3(theme),
  },
  buttonBack: {
    ...globalStyles.button,
    color: globalColors.blue,
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: -4,
      left: -28,
      height: 24,
      width: 24,
      background: 'url(/icons/arrow-left.png)'
    }
  },
});

export default withRouter(withStyles(styles)(inject('userStore', 'trialStore')(observer(Home))));

import classNames from 'classnames';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import { globalColors, globalStyles } from '../../styles/style-constants';

import {
  Grid
} from '@material-ui/core';

const UserManager = ({ classes, userStore, trialStore, trialStore: { currentTrial } }) => {

  return (
    <Grid container spacing={3} justify="flex-end" direction="column" className={classNames(classes.teamWrapper)}>

    </Grid>
  );
};

const styles = theme => ({
  eventWrapper: {
    marginTop: -24
  },
  teamWrapper: {
    background: globalColors.lightestGrey
  },
  widthWrapperFull: {
    width: '100%',
    ...globalStyles.widthWrapper(theme)
  },
});

export default withRouter(withStyles(styles)(inject('userStore', 'trialStore')(observer(UserManager))));

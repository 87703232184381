import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const localizer = momentLocalizer(moment)

const myEventsList = [{
  title: 'test',
  start: moment().toDate(),
  end: moment().add(3, 'hours').toDate()
}];

let components = {

}

const TrialCalendar = props => (
  <div>
    <Calendar
      localizer={localizer}
      events={props.trialStore.trialEvents}
      startAccessor="startDate"
      endAccessor="endDate"
      style={{ height: 500 }}
      views={['month']}
      components={components}
      {...props}
    />
  </div>
)

const styles = theme => {

};

export default withRouter(withStyles(styles)(inject('trialStore')(observer(TrialCalendar))));

// @flow
import axios from 'axios';
import config from '../config';

const GET = 'get';
const PUT = 'put';
const POST = 'post';
const DELETE = 'delete';

const initApi = () => {
  const api = axios.create();
  api.defaults.baseURL = config.apiURL;

  return api;
};

const axiosApi = initApi();

const getBody = (verb: string, body?: any): any => {
  // https://github.com/axios/axios/issues/897#issuecomment-343715381
  if (body !== undefined && verb === DELETE) {
    return { data: body };
  }

  return body;
};

const execute = async (verb: string, url: string, body?: any, additionalConfig: any) => {
  const config = {
    url,
    method: verb,
    data: getBody(verb, body),
    withCredentials: true,
    ...additionalConfig
  };
  const { status, headers, data } = await axiosApi.request(config);

  return {
    status,
    headers,
    data
  };
};

class SunstoneApi {
  async login(token, email) {
    return execute(POST, '/login-cognito', { email }, {
      headers: {
        'accessToken': token
      }
    });
  }

  async register(profile) {
    return execute(POST, '/register-cognito', profile);
  }

  async getUserProfile() {
    return execute(GET, '/users/me');
  }

  async updateUserProfile(profile) {
    return execute(PUT, `/users/${profile.id}`, profile);
  }

  async createTrialUserProgress(progress) {
    return execute(POST, '/trial-user-progress', progress);
  }

  async deleteTrialUserProgress(progressId) {
    return execute(DELETE, `/trial-user-progress/${progressId}`);
  }

  async getCurrentTrial() {
    return execute(GET, '/trials/me');
  }
}

export default new SunstoneApi();

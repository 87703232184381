// @flow
type Config = {
  application: string,
  apiURL: string
};

const config = {
  local: 'local',
  qa: 'qa',
  dev: 'dev',
  prod: 'prod'
};

function getConfig(env: ?string): Config {
  const appConfig = env ?
    require(`./${config[env]}`)
    :
    require(`./${config.local}.js`);

  appConfig.env = env;

  return appConfig;
}

const conf = {
  isLocal: process.env.REACT_APP_BUILD_ENV === 'local',
  isProd: process.env.REACT_APP_BUILD_ENV === 'prod',
  ...getConfig(process.env.REACT_APP_BUILD_ENV)
};

export default conf;

import {
  Grid,
  TextField,
  FormControl,
  FormHelperText,
  Typography
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

function InputField({ classes, labelText, helperText = null, collapsed = false, ...rest }) {
  return (
    <Grid container direction="column" justify="flex-start" className={classes.wrapper}>
      { !collapsed && (
        <Grid item>
          <Typography className={classes.inputLabel}>{labelText}</Typography>
        </Grid>
      )}
      <Grid item xs>
        <FormControl className={classes.formControl}>
          <TextField
            placeholder={collapsed ? labelText : ''}
            multiline
            rows={6}
            id="my-input" 
            aria-describedby="my-helper-text" 
            style={{ 
              backgroundColor: 'white', 
              border: '1px solid #777', 
              padding: 12,
              borderRadius: 8 
            }} 
            {...rest}
          />

          { helperText && (
            <FormHelperText id="my-helper-text">{helperText}</FormHelperText>
          )}
        </FormControl>
      </Grid>
    </Grid>
  )
}

const styles = theme => ({
  formControl: {
    width: '100%'
  },
  wrapper: {
    backgroundColor: 'transparent',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
  },
  inputLabel: theme.typography.label2,
  body1: theme.typography.body1,
  input: theme.form.input
});

export default withStyles(styles)(InputField);

import {
	CognitoUserPool,
  CognitoUser
} from 'amazon-cognito-identity-js';

import config from '../../config';

import { Component } from 'react';

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      code: ''
    };
  }

  resetPassword() {
    const {
      UserPoolId,
      ClientId
    } = config;

    var userPool = new CognitoUserPool({
      UserPoolId,
      ClientId
    });
    
    var userData = {
      Username: this.state.email,
      Pool: userPool,
    };
    var cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmPassword(this.state.code, this.state.newPassword, {
      onSuccess() {
        console.log('Password confirmed!');
      },
      onFailure(err) {
        console.log('Password not confirmed!');
      },
    });
  }

  render() {
    return (
      <div>
        <input value={this.state.email} onChange={e => this.setState({ email: e.target.value })} />
        <input value={this.state.code} onChange={e => this.setState({ code: e.target.value })} />
        <input value={this.state.newPassword} onChange={e => this.setState({ newPassword: e.target.value })} />
        <button onClick={this.resetPassword.bind(this)}>Reset Password</button>
      </div>
    )
  }
}

export default ResetPassword;

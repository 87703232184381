import { makeObservable, observable } from 'mobx';
import SuperuserApi from '../superuser-api';

class SiteStore {
  loadingSites = false;
  sites = [];

  constructor() {
    makeObservable(this, {
      loadingSites: observable,
      sites: observable
    });
  }

  async getSites() {
    if (this.sites.length > 0) {
      return this.sites;
    }

    try {
      this.loadingSites = true;
      const { data } = await SuperuserApi.getSites();
      this.sites = data;
      this.loadingSites = false;
    } catch (e) {
      console.log(e);
      this.loadingSites = false;
    }
  }
}

export default new SiteStore();

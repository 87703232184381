import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  wrapper: {
    fontSize: "16px",
    textDecoration: "none",
    color: "#246ea9",
    fontWeight: 500,
  },
});

class CustomLink extends React.PureComponent {
  render() {
    const {
      classes,
      wrapperClass,
      to,
      children
    } = this.props;
    
    return (
      <Link
        to={to}
        className={classNames(classes.wrapper, wrapperClass)}
      >
        {children}
      </Link>
    )
  }
};

export default withStyles(styles)(CustomLink);

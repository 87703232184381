import { withStyles } from '@material-ui/core/styles';
import { globalStyles, globalColors } from '../../../styles/style-constants';
import classNames from 'classnames';

import {
  Grid,
  Typography
} from '@material-ui/core';

const Home = ({ classes, title, subtitle }) => (
  <Grid container direction="column" className={classNames(classes.headerWrapper)}>
    <Grid item className={classNames(classes.widthWrapperFull)}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography variant="h1" className={classes.headerText}>{title}</Typography>
        </Grid>
        { subtitle && (
          <Grid item>
            <Typography variant="h4" className={classes.headerText}>{subtitle}</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  </Grid>
);

const styles = theme => ({
  headerWrapper: {
    backgroundColor: globalColors.blue,
    paddingTop: 72,
    paddingBottom: 72,
    position: 'relative',
    zIndex: 1,
    '&:before': {  
      content: '""',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundImage: 'url(https://sunstone-storage-public.s3.amazonaws.com/website-resources/Vector.png)',
      position: 'absolute',
      opacity: 0.25,
      zIndex: -1
    }
  },
  headerText: {
    color: globalColors.white
  },
  widthWrapperFull: {
    width: '100%',
    ...globalStyles.widthWrapper(theme)
  },
});

export default withStyles(styles)(Home);

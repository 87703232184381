import React from 'react';
import classNames from 'classnames';

import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import { globalColors, globalStyles } from './styles/style-constants';

import Loading from './components/core/Loading';
import ApplicationHeader from './components/ApplicationHeader';
import ApplicationMenu from './components/ApplicationMenu';

import EventStepper from './components/trial/participant/EventStepper';

import {
  Grid
} from '@material-ui/core';

import Header from './components/trial/common/Header';

class LandingPage extends React.Component {
  componentDidMount() {
    this.props.trialStore.fetchCurrentTrial();
  }
  
  render() {
    const { classes } = this.props;
    const { userStore, trialStore, trialStore: { loadingTrial } } = this.props;

    if (!userStore.user || loadingTrial) {
      return <Loading />;
    }

    return (
      <Grid>
        <ApplicationMenu />
        <Grid container spacing={3} justify="flex-end" direction="column" className={classNames(classes.teamWrapper)}>
          <Grid item>
            <ApplicationHeader />
          </Grid>
          <Grid item>
            <Header 
              title={`Schedule`} />
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column" className={classNames(classes.eventWrapper)}>
            <Grid item className={classNames(classes.widthWrapperFull)}>
              <EventStepper steps={trialStore.trialEvents} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  eventWrapper: {
    marginTop: -24
  },
  teamWrapper: {
    background: globalColors.lightestGrey
  },
  widthWrapperFull: {
    width: '100%',
    ...globalStyles.widthWrapper(theme)
  },
});

export default withRouter(withStyles(styles)(inject('trialStore', 'userStore')(observer(LandingPage))));
import React, { Component } from 'react';
import classNames from 'classnames';

// import { compact, isEmpty } from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography
} from '@material-ui/core';

import ProgressPanel from '../ProgressPanel';
import FormHeader from '../FormHeader';
import InputField from '../InputField';
import TextareaField from '../TextareaField';
import SelectField from '../SelectField';
import { globalStyles } from '../../styles/style-constants';

import { withRouter } from 'react-router-dom';

import Api from '../../modules/serverless-api';

class InterestedForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 0,
      totalSteps: 8,
      facilityName: '',
      facilityAddress: '',
      facilityCity: '',
      facilityState: '',
      facilityCountry: '',
      firstName: '',
      lastName: '',
      email: '',
      therapistCount: '',
      investigatorCount: '',
      interests: {},
      comments: ''
    };
  }

  onNext() {
    this.setState({ currentStep: this.state.currentStep + 1 });
  }

  onPrevious() {
    this.setState({ currentStep: this.state.currentStep - 1 });
  }

  async onComplete() {
    try {
      await Api.submitCenterTherapistForm({
        'SUBMIT DATE': new Date().toString(),
        'FACILITY NAME': this.state.facilityName,
        'FACILITY LOCATION': `${this.state.facilityAddress} ${this.state.facilityCity} ${this.state.facilityState} ${this.state.facilityCountry} `,
        'CONTACT NAME': `${this.state.firstName} ${this.state.lastName}`,
        'EMAIL ADDRESS': this.state.email,
        'THERAPIST COUNT': this.state.therapistCount,
        'INVESTIGATOR COUNT': this.state.investigatorCount,
        'INTERESTS': Object.keys(this.state.interests).join(','),
        'COMMENTS': this.state.comments
      });

      const { history } = this.props;
      history.push('/interested/center/complete');
    } catch(e) {
      alert('There was an error submitting the form. Please try again or contact the administrator.');
    }
  }

  handleDiagnosedCondition = value => e => {
    const { diagnosedConditions } = this.state;

    if (diagnosedConditions[value]) {
      delete diagnosedConditions[value];
      if (value === 'ptsd') {
        this.setState({ militaryExMilitary: null });
      }
    } else {
      diagnosedConditions[value] = true;
    }

    this.setState({ diagnosedConditions });
  }

  handleInterests = value => e => {
    const { interests } = this.state;

    if (interests[value]) {
      delete interests[value];
    } else {
      interests[value] = true;
    }

    this.setState({ interests });
  }

  handleCredentials = value => e => {
    const { credentials } = this.state;

    if (credentials[value]) {
      delete credentials[value];
    } else {
      credentials[value] = true;
    }

    this.setState({ credentials });
  }

  handleTraining = value => e => {
    const { training } = this.state;

    if (training[value]) {
      delete training[value];
    } else {
      training[value] = true;
    }

    this.setState({ training });
  }

  render() {
    const { classes } = this.props;
    const { 
      
      currentStep, 
      totalSteps, 
      facilityName,
      firstName, 
      lastName, 
      email, 
      interests,
      facilityAddress,
      facilityCity, 
      facilityState,
      facilityCountry,
      therapistCount,
      investigatorCount,
      comments
    } = this.state;

    return (
      <div className={classes.patternWrapper}>
        <Grid className={classNames(classes.widthWrapper, classes.panel)} direction="column" justify="space-between" alignItems="center">
          <Grid item xs={12}>
            <Grid container alignItems="center" justify="center" className={classes.content}> 
              <Grid item>
                { currentStep === 0 && (
                  <Grid direction="column" container alignItems="center" justify="center">
                    <Grid item>
                      <Typography className={classes.label}>Tell us about your facility</Typography>
                    </Grid>
                    <Grid item>
                      <FormHeader text="What is the name of your facility?" />
                    </Grid>
                    <Grid item>
                      <Grid container spacing={3} direction="row">
                        <Grid item className={classes.emailInput}>
                          <InputField collapsed={true} labelText={"Facility name"}
                            onChange={e => this.setState({ facilityName: e.target.value })}
                            inputProps={{
                              value: facilityName
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                { currentStep === 1 && (
                  <Grid direction="column" container alignItems="center" justify="center">
                    <Grid item>
                      <Typography className={classes.label}>2/8</Typography>
                    </Grid>
                    <Grid item>
                      <FormHeader text="Where is your facility located?" />
                    </Grid>
                    <Grid item>
                      <Grid container spacing={3} direction="row">
                        <Grid item className={classes.emailInput}>
                          <InputField collapsed={false} labelText={"Address"}
                            onChange={e => this.setState({ facilityAddress: e.target.value })}
                            inputProps={{
                              value: facilityAddress
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} direction="row">
                        <Grid item className={classes.emailInput}>
                          <InputField collapsed={false} labelText={"City"}
                            onChange={e => this.setState({ facilityCity: e.target.value })}
                            inputProps={{
                              value: facilityCity
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} direction="row">
                        <Grid item className={classes.stateCountryInput}>
                          <InputField collapsed={false} labelText={"State"}
                            onChange={e => this.setState({ facilityState: e.target.value })}
                            inputProps={{
                              value: facilityState
                            }}
                          />
                        </Grid>
                        <Grid item className={classes.stateCountryInput}>
                          <InputField collapsed={false} labelText={"Country"} 
                            onChange={e => this.setState({ facilityCountry: e.target.value })}
                            inputProps={{
                              value: facilityCountry
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                { currentStep === 2 && (
                  <Grid direction="column" container alignItems="center" justify="center">
                    <Grid item>
                      <Typography className={classes.label}>3/8</Typography>
                    </Grid>
                    <Grid item>
                      <FormHeader text="What’s the primary contact name?" />
                    </Grid>
                    <Grid item>
                      <Grid container spacing={3} direction="row">
                        <Grid item className={classes.nameInput}>
                          <InputField collapsed={true} labelText={"First Name"}
                            onChange={e => this.setState({ firstName: e.target.value })}
                            inputProps={{
                              value: firstName
                            }}
                          />
                        </Grid>
                        <Grid item className={classes.nameInput}>
                          <InputField collapsed={true} labelText={"Last Name"} 
                            onChange={e => this.setState({ lastName: e.target.value })}
                            inputProps={{
                              value: lastName
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                { currentStep === 3 && (
                  <Grid direction="column" container alignItems="center" justify="center">
                    <Grid item>
                      <Typography className={classes.label}>4/8</Typography>
                    </Grid>
                    <Grid item>
                      <FormHeader text="What’s the primary contact’s email address?" />
                    </Grid>
                    <Grid item className={classes.emailInput}>
                      <Grid container direction="row">
                        <Grid item className={classes.emailInput}>
                          <InputField collapsed={true} labelText={"Your email address"}
                            onChange={e => this.setState({ email: e.target.value })}
                            inputProps={{
                              value: email
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                { currentStep === 4 && (
                  <Grid direction="column" container alignItems="center" justify="center">
                    <Grid item>
                      <Typography className={classes.label}>5/8</Typography>
                    </Grid>
                    <Grid item>
                      <FormHeader text="How many therapists are at your site?" />
                    </Grid>
                    <Grid item className={classes.emailInput}>
                      <Grid container direction="row">
                        <Grid item className={classes.emailInput}>
                          <InputField collapsed={true} labelText={"e.g. 12"}
                            onChange={e => this.setState({ therapistCount: e.target.value })}
                            inputProps={{
                              value: therapistCount
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                { currentStep === 5 && (
                  <Grid direction="column" container alignItems="center" justify="center">
                    <Grid item>
                      <Typography className={classes.label}>6/8</Typography>
                    </Grid>
                    <Grid item>
                      <FormHeader text="How many investigators are at your site?" />
                    </Grid>
                    <Grid item className={classes.emailInput}>
                      <Grid container direction="row">
                        <Grid item className={classes.emailInput}>
                          <InputField collapsed={true} labelText={"e.g. 12"}
                            onChange={e => this.setState({ investigatorCount: e.target.value })}
                            inputProps={{
                              value: investigatorCount
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                { currentStep === 6 && (
                  <Grid direction="column" container alignItems="center" justify="center">
                    <Grid item>
                      <Typography className={classes.label}>7/8</Typography>
                    </Grid>
                    <Grid item>
                      <FormHeader text="What are you interested in?" />
                      <br/>
                      <Typography align="center">Select all that apply.</Typography>
                      <br/>
                    </Grid>
                    <Grid item>
                      <Grid container justify="center" spacing={3} direction="row">
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={interests['becoming-network-site'] != null} onClick={this.handleInterests('becoming-network-site')} labelText={"Becoming a network site"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={interests['building-center-for-research'] != null} onClick={this.handleInterests('building-center-for-research')} labelText={"Building a center for psychedelic research"} />
                        </Grid> 
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={interests['converting-existing-space'] != null} onClick={this.handleInterests('converting-existing-space')} labelText={"Converting an existing space for psychedelic research"} />
                        </Grid> 
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={interests['facilitating-clinical-trial'] != null} onClick={this.handleInterests('facilitating-clinical-trial')} labelText={"Facilitating a clinical trial"} />
                        </Grid> 
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={interests['participating-in-multicenter-clinical-trial'] != null} onClick={this.handleInterests('participating-in-multicenter-clinical-trial')} labelText={"Participating in a multi-center clinical trial"} />
                        </Grid> 
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={interests['finding-therapists-trained'] != null} onClick={this.handleInterests('finding-therapists-trained')} labelText={"Finding therapists trained in psychedelic therapy"} />
                        </Grid> 
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={interests['training-staff-therapist'] != null} onClick={this.handleInterests('training-staff-therapist')} labelText={"Training for staff and therapists"} />
                        </Grid> 
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={interests['data-insights'] != null} onClick={this.handleInterests('data-insights')} labelText={"Data and insights from past and current trials"} />
                        </Grid> 
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                { currentStep === 7 && (
                  <Grid direction="column" container alignItems="center" justify="center">
                    <Grid item>
                      <Typography className={classes.label}>8/8</Typography>
                    </Grid>
                    <Grid item>
                      <FormHeader text="Anything else you'd like to add?" />
                    </Grid>
                    <Grid item className={classes.emailInput}>
                      <Grid container direction="row">
                        <Grid item className={classes.emailInput}>
                          <TextareaField collapsed={true} labelText={"Enter your comment here"}
                            onChange={e => this.setState({ comments: e.target.value })}
                            InputProps={{ disableUnderline: true }}
                            inputProps={{
                              disableUnderline: true,
                              value: comments
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

        <Grid item xs={12}>
            <ProgressPanel
              isFirstStep={currentStep === 0}
              onNext={this.onNext.bind(this)}
              onPrevious={this.onPrevious.bind(this)}
              submitLabel={"Submit"}
              onComplete={this.onComplete.bind(this)}
              percentComplete={Math.round(currentStep / (totalSteps - 1) * 100)} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = theme => ({
  panel: {
    padding: '20px',
    borderRadius: '8px'
  },
  emailInput: {
    width: 624
  },
  genderInput: {
    width: 520
  },
  nameInput: {
    width: 233
  },
  stateCountryInput: {
    width: 312
  },
  label: {
    marginBottom: 20,
    ...globalStyles.label1
  },
  content: {
    minHeight: 'calc(100vh - 120px)'
  },
  patternWrapper: {
    height: '100vh',
    position: 'relative',
    zIndex: 1,
    backgroundColor: theme.globalColors.lightestGrey,
    '&:before': {  
      content: '""',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundImage: 'url(https://sunstone-storage-public.s3.amazonaws.com/website-resources/Vector.png)',
      position: 'absolute',
      opacity: 0.25,
      zIndex: -1
    }
  },
  widthWrapper: globalStyles.widthWrapper(theme)
});

export default withRouter(withStyles(styles)(InterestedForm));
import React from 'react';
import type { Node } from 'react';
import { Route } from 'react-router-dom';

const SuperUserRoute = ({ isSuperUser = true, ssoUrl, component: Component, ...rest }: Props) => {
  const renderComponent = (props): Node => {

    if (isSuperUser) {
      return (
        <Component {...props} />
      );
    } else {
      return (
        <div>Unauthenticated</div>
      );
    }
  };

  const render = props => renderComponent(props);

  return (
    <Route
      {...rest}
      render={render}
    />
  );
};

export default SuperUserRoute;

import { observer, inject } from 'mobx-react';
import React from 'react';

import Loading from './Loading';

class AuthenticatedRoute extends React.Component {

  componentDidMount = () => {
    this.props.userStore.fetchUserProfile()
  }

  goToLoginUrl = () => {
    window.location = this.props.loginUrl;
  };

  render() {
    const { userStore, children = {} } = this.props;
    const { isAuthenticated, isFetchingUserProfile } = userStore;

    if (isFetchingUserProfile) {
      return <Loading />;
    }

    if (!isAuthenticated) {
      this.goToLoginUrl();
    }

    return children;
  }
}

export default inject('userStore')(observer(AuthenticatedRoute));


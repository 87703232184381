import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";

import ThemeSelector from './styles/ThemeSelector';
import {
  CssBaseline
} from '@material-ui/core';

import { Provider } from 'mobx-react';
import UserStore from './modules/user/UserStore';
import TrialStore from './modules/trial/TrialStore';
import SiteStore from './modules/site/SiteStore';

import AuthenticatedRouteContainer from './components/core/AuthenticatedRouteContainer';
import SuperUserRoute from './components/core/SuperUserRoute';

import LandingPage from './LandingPage';
import ProfilePage from './ProfilePage';
import CalendarPage from './CalendarPage';
import TrialDetailPage from './TrialDetailPage';
import UsersPage from './UsersPage';
import UserEditPage from './UserEditPage';

import TherapistInterestedForm from './components/interested/TherapistInterestedForm';
import InterestedForm from './components/interested/InterestedForm';
import RegisterForm from './components/auth/Register';
import LoginForm from './components/auth/Login';
import InterestedComplete from './components/interested/InterestedComplete';
import Resources from './components/interested/Resources';

import InterestedTherapistComplete from './components/interested/InterestedTherapistComplete';
import VerifyForm from './components/auth/Verify';
import ResetPasswordForm from './components/auth/ResetPassword';

import CenterInterestedForm from './components/interested/CenterInterestedForm';
import InterestedCenterComplete from './components/interested/InterestedCenterComplete';

import TrialBuilder from './components/trial/builder/Home';

import './App.css';

function App() {  
  return (
    <ThemeSelector>
      <CssBaseline />

      <div className="WidthWrapper">
        <Router>
          <Provider
            userStore={UserStore}
            trialStore={TrialStore}
            siteStore={SiteStore}
          >
            <Route exact path="/login">
              <LoginForm />
            </Route>
            <Route exact path="/reset-password">
              <ResetPasswordForm />
            </Route>
            <Route exact path="/verify-account">
              <VerifyForm />
            </Route>
            <Route exact path="/register">
              <RegisterForm />
            </Route>
            <Route exact path="/interested/therapist">
              <TherapistInterestedForm />
            </Route>
            <Route exact path="/interested">
              <InterestedForm />
            </Route>
            <Route exact path="/interested/therapist/complete">
              <InterestedTherapistComplete />
            </Route>
            <Route exact path="/resources">
              <Resources />
            </Route>
            <Route exact path="/interested/complete">
              <InterestedComplete />
            </Route>

            <Route exact path="/interested/center">
              <CenterInterestedForm />
            </Route>
            <Route exact path="/interested/center/complete">
              <InterestedCenterComplete />
            </Route>

            <SuperUserRoute exact path="/trials/admin" component={TrialBuilder} />
            <SuperUserRoute exact path="/users" component={UsersPage} />
            <SuperUserRoute exact path="/users/:userId" component={UserEditPage} />

            <SuperUserRoute exact path="/trials/:trialId" component={TrialDetailPage} />

            <Route path="/">
              <AuthenticatedRouteContainer loginUrl="/login">
                <Route exact path="/" component={LandingPage} />
                <Route exact path="/profile" component={ProfilePage} />
                <Route exact path="/schedule" component={CalendarPage} />
              </AuthenticatedRouteContainer>
            </Route>
          </Provider>
        </Router>
      </div>
    </ThemeSelector>
  );
}

export default App;

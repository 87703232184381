import classNames from 'classnames';
import moment from 'moment';
import { observer, inject } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import {
  Grid,
  Typography
} from '@material-ui/core';

import InfoPanel from '../common/InfoPanel';
import Header from '../common/Header';
import ApplicationHeader from '../../ApplicationHeader';
import ApplicationMenu from '../../ApplicationMenu';

import VimeoPlayer from '@vimeo/player';

import { globalColors, globalStyles } from '../../../styles/style-constants';

const Home = ({ classes, userStore, trialStore, trialStore: { currentTrial, loadingTrial, nextEvent } }) => {

  const refContainer = (container) => {
    if (!container) {
      return;
    }

    new VimeoPlayer(container, { id: 500726329, width: container.offsetWidth - 24 });
  }

  return (
    <Grid container spacing={3} justify="flex-end" direction="column" className={classNames(classes.teamWrapper)}>
      <ApplicationMenu />
      <Grid item>
        <ApplicationHeader />
      </Grid>
      <Grid item>
        <Header 
          title={`Welcome back, ${userStore.firstName}`}
          subtitle={trialStore.trialIntroLabel} />
      </Grid>

      <Grid item>
        <Grid container direction="column" className={classNames(classes.headerWrapper)}>
          <Grid item className={classNames(classes.widthWrapperFull)}>
            <Grid container spacing={3}>
              <Grid item>
                <InfoPanel
                  label={"Your Trial"}
                  data={trialStore.trialName}
                  // icon={"icons/alert-circle.png"}
                />
              </Grid>
              <Grid item>
                <InfoPanel
                  label={"Your Trial Day"}
                  data={trialStore.trialDay}
                  // icon={"icons/calendar.png"}
                />
              </Grid>
              <Grid item>
                <InfoPanel
                  label={"Location"}
                  data={trialStore.trialLocations.map(site => (
                    <Typography className={classes.h4}>
                      {site}
                    </Typography>
                  ))}
                  // icon={"icons/map.png"}
                />
              </Grid>
              <Grid item>
                <InfoPanel
                  label={"Your Therapist"}
                  data={trialStore.therapistsName.map(therapist => (
                    <Typography className={classes.h4}>
                      {therapist}
                    </Typography>
                  ))}
                  // icon={"icons/mail.png"}
                />
              </Grid>
              <Grid item>
                <InfoPanel
                  label={"Your Doctor(s)"}
                  data={trialStore.doctorsNames.map(doctor => (
                    <Typography className={classes.h4}>
                      {doctor}
                    </Typography>
                  ))}
                  // icon={"icons/mail.png"}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <br/>
        <br/>

        <Grid container direction="column" className={classNames(classes.headerWrapper)}>
          <Grid item className={classNames(classes.widthWrapperFull)}>
            <Grid container className={classes.primaryPanel}>
              <Typography className={classes.h4White}>Preparing for your trial</Typography>
            
              <Grid container spacing={3} style={{ marginTop: 50 }}>
                <Grid item sm={12} md={4}>
                  <Grid container spacing={3}>
                    <Grid item xs={2}>
                      <Typography className={classes.displayWhite}>"</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography className={classes.h4White}>
                        Hi folks. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                      </Typography>
                      <br/>
                      <br/>
                      <Typography className={classes.label1White}>
                        - Bill Richards
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid md={8}>
                   <div
                    ref={refContainer}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <br/>
        <br/>

        <Grid container direction="column" className={classNames(classes.headerWrapper)}>
          <Grid item className={classNames(classes.widthWrapperFull)}>
            <Grid container className={classes.secondaryPanel}>
              <Grid container justify="space-between">
                <Grid item>
                  <Typography className={classes.h4}>Schedule</Typography>
                </Grid>
                { nextEvent && (
                  <Grid item>
                    <Typography className={classes.label1}>{moment(nextEvent.startDate).format('MMMM D, YYYY')}</Typography>
                  </Grid>
                )}
              </Grid>

              { nextEvent && (
                <Grid container spacing={3} style={{ marginTop: 50 }}>
                  <Grid item sm={12} md={4}>
                    <Typography className={classes.h3Blue}>{nextEvent.name}</Typography>
                  </Grid>
                  <Grid md={8}>
                    <Typography>{nextEvent.description}</Typography>
                  </Grid>
                </Grid>
              )}

              <Grid style={{ marginTop: 48 }}>
                <Link style={{textDecoration: 'none'}} to={{
                  pathname: "/schedule",
                  state: { fromDashboard: true }
                }}>
                  <Typography className={classes.button}>See what I need to do</Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  );
}

const styles = theme => ({
  eventWrapper: {
    marginTop: -24
  },
  teamWrapper: {
    background: globalColors.lightestGrey
  },
  widthWrapperFull: {
    width: '100%',
    ...globalStyles.widthWrapper(theme)
  },
  displayWhite: {
    ...globalStyles.display,
    color: globalColors.white
  },
  label1White: {
    ...globalStyles.label1,
    color: globalColors.white
  },
  label1: {
    ...globalStyles.label1
  },
  h3Blue: {
    ...globalStyles.h3(theme),
    color: globalColors.blue
  },
  primaryPanel: {
    background: globalColors.blue,
    paddingTop: 32,
    paddingBottom: 32,
    paddingLeft: 24,
    paddingRight: 24,
    boxShadow: '2px 2px 11px -2px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px'
  },
  secondaryPanel: {
    background: globalColors.white,
    paddingTop: 32,
    paddingBottom: 32,
    paddingLeft: 24,
    paddingRight: 24,
    boxShadow: '2px 2px 11px -2px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px'
  },
  button: {
    ...globalStyles.button,
    color: globalColors.blue,
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: -4,
      right: -28,
      height: 24,
      width: 24,
      background: 'url(/icons/arrow-right.png)'
    }
  },
  h4White: {
    ...globalStyles.h4,
    color: globalColors.white
  },
  h4: {
    ...globalStyles.h4,
  }
});

export default withRouter(withStyles(styles)(inject('userStore', 'trialStore')(observer(Home))));

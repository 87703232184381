import { withStyles } from '@material-ui/core/styles';
import { globalStyles, globalColors } from '../../../styles/style-constants';

import {
  Grid,
  Typography
} from '@material-ui/core';

const InfoPanel = ({ classes, label, data, icon }) => (
  <Grid className={classes.wrapper}>
    <Grid container justify="space-between">
      <Grid item>
        <Typography className={classes.label}>{label}</Typography>
      </Grid>
      { icon && (
        <Grid item>
          <img alt="alt" width="24" src={icon} />
        </Grid>
      )}
    </Grid>
    
    <Typography className={classes.h4}>{data}</Typography>
  </Grid>
);

const styles = theme => ({
  wrapper: {
    background: globalColors.white,
    padding: 24,
    minWidth: 230,
    boxShadow: '2px 2px 11px -2px rgba(0, 0, 0, 0.1)'
  },
  label: {
    ...globalStyles.label1,
    color: globalColors.darkGrey,
    marginBottom: 24
  },
  h4: {
    ...globalStyles.h4,
  }
});

export default withStyles(styles)(InfoPanel);

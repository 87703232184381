import React, { Component } from 'react';
import classNames from 'classnames';

import { compact, isEmpty } from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography
} from '@material-ui/core';

import ProgressPanel from '../ProgressPanel';
import FormHeader from '../FormHeader';
import InputField from '../InputField';
import SelectField from '../SelectField';
import TextareaField from '../TextareaField';
import { globalStyles } from '../../styles/style-constants';

import { withRouter } from 'react-router-dom';

import Api from '../../modules/serverless-api';

class InterestedForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showBranding: window.location.search !== '?internal=true',
      currentStep: 0,
      totalSteps: 9,
      firstName: '',
      lastName: '',
      interestedTrialText: '',
      email: '',
      zipCode: '',
      phoneNumber: '',
      age: '',
      gender: '',
      diagnosedConditions: {},
      ethnicities: {},
      willingToTravel: '',
      otherConditions: '',
      militaryExMilitary: '',
      takingAntiDepressants: '',
      interestedTrials: {},
      showPatientInterestedForm: false,
      availableTrials: [{
        name: 'Psilocybin in patients with cancer and coping with depression',
        description: ''
      }, {
        name: 'MDMA assisted Psychotherapy for Patients with Treatment Resistant PTSD',
        description: ''
      }]
    };
  }

  onNext() {
    this.setState({ currentStep: this.state.currentStep + 1 });
  }

  onPrevious() {
    this.setState({ currentStep: this.state.currentStep - 1 });
  }

  async onComplete() {
    try {
      await Api.submitInterestedForm({
        'SUBMIT DATE': new Date().toString(),
        'FIRST NAME': this.state.firstName,
        'LAST NAME': this.state.lastName,
        'EMAIL ADDRESS': this.state.email,
        'ZIP CODE': this.state.zipCode,
        'PHONE NUMBER': this.state.phoneNumber,
        'TAKING ANTIDEPRESSANTS': this.state.takingAntiDepressants,
        'DOB': this.state.age,
        'GENDER': this.state.gender,
        'ETHNICITY': `${compact(Object.keys(this.state.ethnicities)).join(',')}`,
        'TRIAL INTEREST': Object.keys( this.state.interestedTrials).join(',') + " " + this.state.interestedTrialText,
        'WILLING TO TRAVEL 50 MILES': this.state.willingToTravel,
        'DIAGNOSED CONDITIONS': `${compact(Object.keys(this.state.diagnosedConditions).concat([this.state.otherConditions])).join(',')} military: ${this.state.militaryExMilitary} `
      });

      const { history } = this.props;
      if (this.state.interestedTrials[`MAPS EAMP1`]) {
        history.push('/interested/complete?ea=true');
      } else {
        history.push('/interested/complete');
      }
    } catch(e) {
      alert('There was an error submitting the form. Please try again or contact the administrator.');
    }
  }

  handleDiagnosedCondition = value => e => {
    const { diagnosedConditions } = this.state;

    if (diagnosedConditions[value]) {
      delete diagnosedConditions[value];
      if (value === 'ptsd') {
        this.setState({ militaryExMilitary: null });
      }
    } else {
      diagnosedConditions[value] = true;
    }

    this.setState({ diagnosedConditions });
  }

  handleInterestedTrial = value => e => {
    const { interestedTrials } = this.state;

    if (interestedTrials[value]) {
      delete interestedTrials[value];
    } else {
      interestedTrials[value] = true;
    }

    this.setState({ interestedTrials });
  }

  handleEthnicity = value => e => {
    const { ethnicities } = this.state;

    if (ethnicities[value]) {
      delete ethnicities[value];
    } else {
      ethnicities[value] = true;
    }

    this.setState({ ethnicities });
  }

  render() {
    window.location.href = 'https://sunstonetherapies.my.site.com/guests/s/';
    
    const { classes } = this.props;
    const { 
      interestedTrialText,
      currentStep, 
      totalSteps, 
      firstName, 
      lastName, 
      email, 
      zipCode, 
      phoneNumber,
      age, 
      gender, 
      diagnosedConditions, 
      ethnicities, 
      takingAntiDepressants,
      willingToTravel, 
      availableTrials, 
      interestedTrials, 
      otherConditions,
      militaryExMilitary,
      showBranding
    } = this.state;

    return (
      <div className={classes.patternWrapper}>
        <Grid item xs={12}>
          { showBranding && (
            <Grid style={{ position: 'absolute', top: 0 }} spacing={3} direction="column" container alignItems="center" justify="center"> 
              <Grid item style={{ paddingTop: 24 }}>
                <img className={classes.logo} alt="logo" src="/Full.png" />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid className={classNames(classes.widthWrapper, classes.panel)} direction="column" justify="space-between" alignItems="center">
          <Grid item xs={12}>
            <Grid container alignItems="center" justify="center" className={classes.content}> 
              <Grid item>
                { currentStep === 0 && (
                  <div>
                    { true && (
                      <Grid item xs={12} style={{ paddingTop: 48 }} className={classes.alertHeading}>
                        <Typography className={classes.alert} style={{ fontFamily: 'Kumbh Sans', textAlign: 'center', background: '#d2dce1', color: 'black' }}>To learn more about upcoming Sunstone Therapies trials, please share your information.</Typography>
                      </Grid>
                    )}
                    <Grid direction="column" container alignItems="center" justify="center">
                      <Grid item>
                        <Typography className={classes.label}>Tell us about yourself</Typography>
                      </Grid>
                      <Grid item>
                        <FormHeader text="What's your name?" />
                      </Grid>
                      <Grid item>
                        <Grid container spacing={3} direction="row">
                          <Grid item className={classes.nameInput}>
                            <InputField collapsed={true} labelText={"First Name"}
                              onChange={e => this.setState({ firstName: e.target.value })}
                              inputProps={{
                                value: firstName
                              }}
                            />
                          </Grid>
                          <Grid item className={classes.nameInput}>
                            <InputField collapsed={true} labelText={"Last Name"} 
                              onChange={e => this.setState({ lastName: e.target.value })}
                              inputProps={{
                                value: lastName
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                )}

                { currentStep === 1 && (
                  <Grid direction="column" container alignItems="center" justify="center">
                    <Grid item>
                      <Typography className={classes.label}>2/9</Typography>
                    </Grid>
                    <Grid item>
                      <FormHeader text="What’s your email address?" />
                    </Grid>
                    <Grid item className={classes.emailInput}>
                      <Grid container direction="row">
                        <Grid item className={classes.emailInput}>
                          <InputField collapsed={true} labelText={"Your email address"}
                            onChange={e => this.setState({ email: e.target.value })}
                            inputProps={{
                              value: email
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                { currentStep === 2 && (
                  <Grid direction="column" container alignItems="center" justify="center">
                    <Grid item>
                      <Typography className={classes.label}>3/9</Typography>
                    </Grid>
                    <Grid item>
                      <FormHeader text="What's your phone number?" />
                    </Grid>
                    <Grid item className={classes.emailInput}>
                      <Grid container direction="row">
                        <Grid item className={classes.emailInput}>
                          <InputField collapsed={true} labelText={"e.g. 555-555-5555"}
                            onChange={e => this.setState({ phoneNumber: e.target.value })}
                            inputProps={{
                              value: phoneNumber
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                { currentStep === 3 && (
                  <Grid direction="column" container alignItems="center" justify="center">
                    <Grid item>
                      <Typography className={classes.label}>3/9</Typography>
                    </Grid>
                    <Grid item>
                      <FormHeader text="Where do you live?" />
                    </Grid>
                    <Grid item className={classes.emailInput}>
                      <Grid container direction="row">
                        <Grid item className={classes.emailInput}>
                          <InputField collapsed={true} labelText={"Your zipcode is enough"}
                            onChange={e => this.setState({ zipCode: e.target.value })}
                            inputProps={{
                              value: zipCode
                            }}
                          />
                        </Grid>
                        <Grid item className={classes.travelInput}>
                          <SelectField panelStyle={{ background: 'transparent', boxShadow: 'none', marginLeft: -24 }} type="checkbox" checked={willingToTravel === 'yes'} onClick={() => this.setState({ willingToTravel: this.state.willingToTravel === 'yes' ? 'no' : 'yes' })} labelText={"Please check if you are willing to travel more than 50 miles."} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                { currentStep === 4 && (
                  <Grid direction="column" container alignItems="center" justify="center">
                    <Grid item>
                      <Typography className={classes.label}>4/9</Typography>
                    </Grid>
                    <Grid item>
                      <FormHeader text="What is your date of birth?" />
                    </Grid>
                    <Grid item className={classes.emailInput}>
                      <Grid container direction="row">
                        <Grid item className={classes.emailInput}>
                          <InputField collapsed={true} labelText={"MM/DD/YYYY"}
                            onChange={e => this.setState({ age: e.target.value })}
                            inputProps={{
                              value: age
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                { currentStep === 5 && (
                  <Grid className={classes.genderContainer} direction="column" container alignItems="center" justify="center">
                    <Grid item>
                      <Typography className={classes.label}>5/9</Typography>
                    </Grid>
                    <Grid item>
                      <FormHeader text="What is your gender?" />
                    </Grid>
                    <Grid item>
                      <Grid container justify="center" spacing={3} direction="row">
                        <Grid item className={classes.genderInput}>
                          <SelectField type="radio" checked={gender === 'female'} onClick={e => this.setState({ gender: 'female' })} labelText={"Female"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="radio" checked={gender === 'male'} onClick={e => this.setState({ gender: 'male' })} labelText={"Male"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="radio" checked={gender === 'nonbinary'} onClick={e => this.setState({ gender: 'nonbinary' })} labelText={"Non-binary"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="radio" checked={gender === 'other'} onClick={e => this.setState({ gender: 'other' })} labelText={"Other"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="radio" checked={gender === 'not-say'} onClick={e => this.setState({ gender: 'not-say' })} labelText={"Prefer not to say"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>

                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                { currentStep === 6 && (
                  <Grid className={classes.genderContainer} direction="column" container alignItems="center" justify="center">
                    <Grid item>
                      <Typography className={classes.label}>6/9</Typography>
                    </Grid>
                    <Grid item>
                      <FormHeader text="What is your ethnicity?" />
                    </Grid>
                    <Grid item>
                      <Grid container justify="center" spacing={3} direction="row">
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={ethnicities['white'] != null} onClick={this.handleEthnicity('white')} labelText={"White or Caucasian"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={ethnicities['asian'] != null} onClick={this.handleEthnicity('asian')} labelText={"Asian, Asian American, Pacific Islander, South Asian"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={ethnicities['black'] != null} onClick={this.handleEthnicity('black')} labelText={"African American, Black"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={ethnicities['middleeasternarabnorthafrican'] != null} onClick={this.handleEthnicity('middleeasternarabnorthafrican')} labelText={"Middle Eastern, Arab, North African"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={ethnicities['americanindian'] != null} onClick={this.handleEthnicity('americanindian')} labelText={"Native American, Alaskan Native"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={ethnicities['latino'] != null} onClick={this.handleEthnicity('latino')} labelText={"Hispanic, Latin(o/a), Latinx"} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                { currentStep === 7 && (
                  <Grid className={classNames(classes.genderContainer, classes.genderContainer2)} direction="column" container alignItems="center" justify="center">
                    <Grid item>
                      <Typography className={classes.label}>7/9</Typography>
                    </Grid>
                    <Grid item align="center">
                      <FormHeader text="Are you currently experiencing any of the following conditions?" />
                      <br/>
                      <br/>
                      <Typography>Select all that apply.</Typography>
                      <br/>
                      <br/>
                    </Grid>
                    <Grid item>
                      <Grid container justify="center" spacing={3} direction="row">
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={diagnosedConditions['cancer'] != null} onClick={this.handleDiagnosedCondition('cancer')} labelText={"Cancer"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={diagnosedConditions['addiction'] != null} onClick={this.handleDiagnosedCondition('addiction')} labelText={"Addiction"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={diagnosedConditions['anxiety'] != null} onClick={this.handleDiagnosedCondition('anxiety')} labelText={"Anxiety"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={diagnosedConditions['trd'] != null} onClick={this.handleDiagnosedCondition('trd')} labelText={"Treatment Resistant Depression (TRD)"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={diagnosedConditions['depression'] != null} onClick={this.handleDiagnosedCondition('depression')} labelText={"Depression"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={diagnosedConditions['eating-disorder'] != null} onClick={this.handleDiagnosedCondition('eating-disorder')} labelText={"Eating Disorder"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField 
                            type="checkbox" 
                            checked={diagnosedConditions['ptsd'] != null || !isEmpty(militaryExMilitary)} 
                            onClick={this.handleDiagnosedCondition('ptsd')} 
                            labelText={"PTSD"}
                            selectLabel={"Are you in the military or ex-military?"}
                            selectOptions={['Yes', 'No']}
                            onSelectInputChange={e => this.setState({ militaryExMilitary: e.target.value })}
                            selectValue={militaryExMilitary}
                          />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField 
                            type="checkbox" 
                            checked={diagnosedConditions['other'] != null || !isEmpty(otherConditions)} 
                            onClick={this.handleDiagnosedCondition('other')} 
                            onInputChange={e => this.setState({ otherConditions: e.target.value })} 
                            inputValue={otherConditions} 
                            labelPlaceholder={"Enter your info here"} 
                            labelText={"Other:"} 
                          />
                        </Grid>
                        <Grid item className={classes.genderInput}>

                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                { currentStep === 8 && (
                  <Grid className={classes.genderContainer} direction="column" container alignItems="center" justify="center">
                    <Grid item>
                      <Typography className={classes.label}>8/9</Typography>
                    </Grid>
                    <Grid item align="center">
                      <FormHeader text="Are you interested in a specific trial?" />
                      <br/>
                      <br/>
                      <Typography>Which trial(s) are you interested in?</Typography>
                      <br/>
                      <br/>
                    </Grid>
                    <Grid item>
                      <Grid container justify="center" spacing={3} direction="row">
                        {/* { availableTrials.map(trial => {
                          return (
                            <Grid item className={classes.genderInput}>
                              <SelectField type="checkbox" checked={interestedTrials[trial.name] != null} onClick={this.handleInterestedTrial(trial.name)} labelText={trial.name} description={trial.description} />
                            </Grid>
                          );
                        }) } */}
                        <Grid item xs={10}>
                        <TextareaField collapsed={true} labelText={"Which trial(s) are you interested in?"}
                            onChange={e => this.setState({ interestedTrialText: e.target.value })}
                            InputProps={{ disableUnderline: true }}
                            inputProps={{
                              disableUnderline: true,
                              value: interestedTrialText
                            }}
                          />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={interestedTrials[`MAPS EAMP1`] != null} onClick={this.handleInterestedTrial(`MAPS EAMP1`)} labelText={"MAPS EAMP1"} description={"An expanded access program for persons with treatment-resistant PTSD"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={interestedTrials[`COMP 104`] != null} onClick={this.handleInterestedTrial(`COMP 104`)} labelText={"COMP 104"} description={"A study using psilocybin to treat major depression"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField
                            type="checkbox"
                            checked={interestedTrials[`Dyad Study`] != null}
                            onClick={this.handleInterestedTrial(`Dyad Study`)}
                            labelText={"Dyad Study"}
                            description={"MDMA-assisted therapy for adjustment disorder (AD) in dyads of patients with cancer and a concerned significant other"} 
                            selectLabel={"Are you currently taking any anti-depression medications?"}
                            selectOptions={['Yes', 'No']}
                            onSelectInputChange={e => {
                              e.stopPropagation();
                              e.preventDefault();

                              this.setState({ takingAntiDepressants: e.target.value })
                            }}
                            selectValue={takingAntiDepressants}
                            />
                        </Grid>

                        { availableTrials.length % 2 === 0 && (
                          <Grid item className={classes.genderInput}>

                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

        <Grid item xs={12}>
            <ProgressPanel
              isFirstStep={currentStep === 0}
              onNext={this.onNext.bind(this)}
              onPrevious={this.onPrevious.bind(this)}
              submitLabel={"Submit"}
              onComplete={this.onComplete.bind(this)}
              percentComplete={Math.round(currentStep / (totalSteps - 1) * 100)} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = theme => ({
  panel: {
    padding: '20px',
    borderRadius: '8px'
  },
  emailInput: {
    width: 624,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  genderInput: {
    width: 520,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  travelInput: {
    width: 520,
    background: 'transparent',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  nameInput: {
    width: 233,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  label: {
    marginBottom: 20,
    ...globalStyles.label1
  },
  logo: {
    [theme.breakpoints.down('md')]: {
      width: 100
    }
  },
  alertHeading: {
    paddingBottom: 64,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 16,
      marginTop: 16
    }
  },
  alert: {
    padding: 32, 
    paddingLeft: 48, 
    paddingRight: 48,
    fontSize: '1.2rem',
    maxWidth: 800,
    [theme.breakpoints.down('sm')]: {
      padding: 16,
      fontSize: '0.8rem',
      maxWidth: '100%',
    }
  },
  genderContainer: {
    [theme.breakpoints.down('md')]: {
      paddingTop: 120
    }
  },
  genderContainer2: {
    [theme.breakpoints.up('md')]: {
      paddingTop: 120
    }
  },
  content: {
    minHeight: 'calc(100vh - 120px)',
  },
  patternWrapper: {
    height: '100vh',
    position: 'relative',
    zIndex: 1,
    backgroundColor: theme.globalColors.lightestGrey,
    '&:before': {  
      content: '""',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundImage: 'url(https://sunstone-storage-public.s3.amazonaws.com/website-resources/Vector.png)',
      position: 'absolute',
      opacity: 0.25,
      zIndex: -1
    }
  },
  widthWrapper: globalStyles.widthWrapper(theme)
});

export default withRouter(withStyles(styles)(InterestedForm));
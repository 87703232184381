import React from 'react';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import { globalStyles, globalColors } from '../../../styles/style-constants';

const styles = theme => ({
  wrapper: {
    lineHeight: "24px",
    paddingLeft: theme.spacing(3),
  },
  label1: {
    ...globalStyles.label1,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 8
  },
  developing: {
    color: globalColors.green
  }
});

class TrialStatusFormatter extends React.PureComponent {
  render() {
    const {
      classes,
      wrapperClass,
      status
    } = this.props;

    return (
      <Grid
        container
        direction="column"
        className={classNames(classes.wrapper, wrapperClass)}
      >
        <Grid item>
          <Typography className={classNames(classes.label1, classes[status])}>
            {status}
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(TrialStatusFormatter);
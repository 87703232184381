/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useCallback } from 'react';

function usePagingAndSorting(intialState) {
  const [pageIndex, setPageIndex] = useState(intialState.pageIndex);
  const [sortInfo, setSortInfo] = useState(intialState.sortInfo);

  const onSortChange = useCallback((updatedSortInfo) => {
    setSortInfo({
      ...sortInfo,
      ...updatedSortInfo,
    });
  }, [sortInfo, setSortInfo]);

  const onSortReset = useCallback(() => {
    setSortInfo({
      sortKey: null,
      isAscOrder: false,
    });
  }, [setSortInfo]);

  return [pageIndex, setPageIndex, sortInfo, onSortChange, onSortReset];
}

export default usePagingAndSorting;

import React, { Component } from 'react';
import classNames from 'classnames';

// import { compact, isEmpty } from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography
} from '@material-ui/core';

import ProgressPanel from '../ProgressPanel';
import FormHeader from '../FormHeader';
import InputField from '../InputField';
import SelectField from '../SelectField';
import { globalStyles } from '../../styles/style-constants';

import { withRouter } from 'react-router-dom';

import Api from '../../modules/serverless-api';

class InterestedForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 0,
      totalSteps: 6,
      firstName: '',
      lastName: '',
      email: '',
      zipCode: '',
      interests: {},
      credentials: {},
      training: {},
      receivedTraining: '',
      trainingPlace: ''
      // }, {
      //   name: 'EA with MAPS',
      //   description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
      // }, {
      //   name: 'Bossis / Grob',
      //   description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
      // }, {
      //   name: 'Dyad Trial with MAPS',
      //   description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
      // }, {
      //   name: 'Diversity Study',
      //   description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
      // }]
    };
  }

  onNext() {
    this.setState({ currentStep: this.state.currentStep + 1 });
  }

  onPrevious() {
    this.setState({ currentStep: this.state.currentStep - 1 });
  }

  async onComplete() {
    try {
      await Api.submitInterestedTherapistForm({
        'SUBMIT DATE': new Date().toString(),
        'FIRST NAME': this.state.firstName,
        'LAST NAME': this.state.lastName,
        'EMAIL ADDRESS': this.state.email,
        'ZIP CODE': this.state.zipCode,
        'INTERESTS': Object.keys(this.state.interests).join(','),
        'CREDENTIALS': Object.keys(this.state.credentials).join(','),
        'TRAINING': `${this.state.receivedTraining}: ${this.state.trainingPlace}`
      });

      const { history } = this.props;
      history.push('/interested/therapist/complete');
    } catch(e) {
      alert('There was an error submitting the form. Please try again or contact the administrator.');
    }
  }

  handleDiagnosedCondition = value => e => {
    const { diagnosedConditions } = this.state;

    if (diagnosedConditions[value]) {
      delete diagnosedConditions[value];
      if (value === 'ptsd') {
        this.setState({ militaryExMilitary: null });
      }
    } else {
      diagnosedConditions[value] = true;
    }

    this.setState({ diagnosedConditions });
  }

  handleInterests = value => e => {
    const { interests } = this.state;

    if (interests[value]) {
      delete interests[value];
    } else {
      interests[value] = true;
    }

    this.setState({ interests });
  }

  handleCredentials = value => e => {
    const { credentials } = this.state;

    if (credentials[value]) {
      delete credentials[value];
    } else {
      credentials[value] = true;
    }

    this.setState({ credentials });
  }

  handleTraining = value => e => {
    const { training } = this.state;

    if (training[value]) {
      delete training[value];
    } else {
      training[value] = true;
    }

    this.setState({ training });
  }

  render() {
    const { classes } = this.props;
    const { 
      
      currentStep, 
      totalSteps, 
      firstName, 
      lastName, 
      email, 
      zipCode, 
      interests,
      credentials,
      trainingPlace,
      receivedTraining, 
    } = this.state;

    return (
      <div className={classes.patternWrapper}>
        <Grid className={classNames(classes.widthWrapper, classes.panel)} direction="column" justify="space-between" alignItems="center">
          <Grid item xs={12}>
            <Grid container alignItems="center" justify="center" className={classes.content}> 
              <Grid item>
                { currentStep === 0 && (
                  <Grid direction="column" container alignItems="center" justify="center">
                    <Grid item>
                      <Typography className={classes.label}>Tell us about yourself</Typography>
                    </Grid>
                    <Grid item>
                      <FormHeader text="What's your name?" />
                    </Grid>
                    <Grid item>
                      <Grid container spacing={3} direction="row">
                        <Grid item className={classes.nameInput}>
                          <InputField collapsed={true} labelText={"First Name"}
                            onChange={e => this.setState({ firstName: e.target.value })}
                            inputProps={{
                              value: firstName
                            }}
                          />
                        </Grid>
                        <Grid item className={classes.nameInput}>
                          <InputField collapsed={true} labelText={"Last Name"} 
                            onChange={e => this.setState({ lastName: e.target.value })}
                            inputProps={{
                              value: lastName
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                { currentStep === 1 && (
                  <Grid direction="column" container alignItems="center" justify="center">
                    <Grid item>
                      <Typography className={classes.label}>2/6</Typography>
                    </Grid>
                    <Grid item>
                      <FormHeader text="What’s your email address?" />
                    </Grid>
                    <Grid item className={classes.emailInput}>
                      <Grid container direction="row">
                        <Grid item className={classes.emailInput}>
                          <InputField collapsed={true} labelText={"Your email address"}
                            onChange={e => this.setState({ email: e.target.value })}
                            inputProps={{
                              value: email
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                { currentStep === 2 && (
                  <Grid direction="column" container alignItems="center" justify="center">
                    <Grid item>
                      <Typography className={classes.label}>3/6</Typography>
                    </Grid>
                    <Grid item>
                      <FormHeader text="Where do you live?" />
                    </Grid>
                    <Grid item className={classes.emailInput}>
                      <Grid container direction="row">
                        <Grid item className={classes.emailInput}>
                          <InputField collapsed={true} labelText={"Your zipcode is enough"}
                            onChange={e => this.setState({ zipCode: e.target.value })}
                            inputProps={{
                              value: zipCode
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                { currentStep === 3 && (
                  <Grid direction="column" container alignItems="center" justify="center">
                    <Grid item>
                      <Typography className={classes.label}>4/6</Typography>
                    </Grid>
                    <Grid item>
                      <FormHeader text="What are you interested in?" />
                    </Grid>
                    <Grid item>
                      <Grid container justify="center" spacing={3} direction="row">
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={interests['training'] != null} onClick={this.handleInterests('training')} labelText={"Training"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={interests['workingontrial'] != null} onClick={this.handleInterests('workingontrial')} labelText={"Working on a trial"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={interests['bestpractices'] != null} onClick={this.handleInterests('bestpractices')} labelText={"Best practices"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={interests['researchfindings'] != null} onClick={this.handleInterests('researchfindings')} labelText={"Research findings"} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                { currentStep === 4 && (
                  <Grid direction="column" container alignItems="center" justify="center">
                    <Grid item>
                      <Typography className={classes.label}>5/6</Typography>
                    </Grid>
                    <Grid item>
                      <FormHeader text="What are your credentials?" />
                    </Grid>
                    <Grid item>
                      <Grid container justify="center" spacing={3} direction="row">
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={credentials['md'] != null} onClick={this.handleCredentials('md')} labelText={"MD"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={credentials['aprn'] != null} onClick={this.handleCredentials('aprn')} labelText={"APRN"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={credentials['ma'] != null} onClick={this.handleCredentials('ma')} labelText={"MA"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={credentials['bc'] != null} onClick={this.handleCredentials('bc')} labelText={"BC"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={credentials['phd'] != null} onClick={this.handleCredentials('phd')} labelText={"PhD"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={credentials['bsn'] != null} onClick={this.handleCredentials('bsn')} labelText={"BSN"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={credentials['rn'] != null} onClick={this.handleCredentials('rn')} labelText={"RN"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={credentials['pa'] != null} onClick={this.handleCredentials('pa')} labelText={"PA"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={credentials['lcsw'] != null} onClick={this.handleCredentials('lcsw')} labelText={"LCSW"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={credentials['lmft'] != null} onClick={this.handleCredentials('lmft')} labelText={"LMFT"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={credentials['lcswc'] != null} onClick={this.handleCredentials('lcswc')} labelText={"LCSW-C"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={credentials['pac'] != null} onClick={this.handleCredentials('pac')} labelText={"PA-C"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={credentials['lcswcryt'] != null} onClick={this.handleCredentials('lcswcryt')} labelText={"LCSW-C RYT"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={credentials['mph'] != null} onClick={this.handleCredentials('mph')} labelText={"MPH"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={credentials['lcpc'] != null} onClick={this.handleCredentials('lcpc')} labelText={"LCPC"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>

                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                { currentStep === 5 && (
                  <Grid direction="column" container alignItems="center" justify="center">
                    <Grid item>
                      <Typography className={classes.label}>6/6</Typography>
                    </Grid>
                    <Grid item>
                      <FormHeader text="Have you received training in psychedelic therapy?" />
                    </Grid>
                    <Grid item>
                      <Grid container justify="center" spacing={3} direction="row">
                        <Grid item className={classes.genderInput}>
                          <SelectField 
                            type="radio" 
                            checked={receivedTraining === 'yes'} 
                            onClick={e => this.setState({ receivedTraining: 'yes' })} 
                            labelText={"Yes"}
                            onInputChange={e => this.setState({ trainingPlace: e.target.value })} 
                            inputValue={trainingPlace} 
                            labelPlaceholder={"Enter the place of training info here"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="radio" checked={receivedTraining === 'no'} onClick={e => this.setState({ receivedTraining: 'no' })} labelText={"No"} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

        <Grid item xs={12}>
            <ProgressPanel
              isFirstStep={currentStep === 0}
              onNext={this.onNext.bind(this)}
              onPrevious={this.onPrevious.bind(this)}
              submitLabel={"Submit"}
              onComplete={this.onComplete.bind(this)}
              percentComplete={Math.round(currentStep / (totalSteps - 1) * 100)} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = theme => ({
  panel: {
    padding: '20px',
    borderRadius: '8px'
  },
  emailInput: {
    width: 624
  },
  genderInput: {
    width: 520
  },
  nameInput: {
    width: 233
  },
  label: {
    marginBottom: 20,
    ...globalStyles.label1
  },
  content: {
    minHeight: 'calc(100vh - 120px)'
  },
  patternWrapper: {
    height: '100vh',
    position: 'relative',
    zIndex: 1,
    backgroundColor: theme.globalColors.lightestGrey,
    '&:before': {  
      content: '""',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundImage: 'url(https://sunstone-storage-public.s3.amazonaws.com/website-resources/Vector.png)',
      position: 'absolute',
      opacity: 0.25,
      zIndex: -1
    }
  },
  widthWrapper: globalStyles.widthWrapper(theme)
});

export default withRouter(withStyles(styles)(InterestedForm));
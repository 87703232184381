import moment from 'moment';
import React from 'react';

import classNames from 'classnames';
import ConnectElements from 'react-connect-elements';

import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

// import Stepper from '@material-ui/core/Stepper';
// import Step from '@material-ui/core/Step';
// import StepLabel from '@material-ui/core/StepLabel';
// import StepContent from '@material-ui/core/StepContent';
import Grid from '@material-ui/core/Grid';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';

// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Button from '../../Button';
import { globalColors, globalStyles } from '../../../styles/style-constants';

const EventStepper = ({ classes, steps, trialStore }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleComplete = s => async () => {
    await trialStore.markEventComplete(s.id);
    await trialStore.fetchCurrentTrial(true);
  }

  const handleUndoComplete = s => async () => {
    await trialStore.deleteTrialUserProgress(s.id);
    await trialStore.fetchCurrentTrial(true);
  }

  return (
    <div className="elements">
      { steps.map((s, i) => {
        return (
          <div className={classNames(`element${i}`, (i !== 0 && i !== (steps.length - 1)) ? classes.accordionContainerLine : null, i === (steps.length - 1) ? classes.accordionContainerLineLast : null, i === 0 ? classes.accordionContainerLineFirst : null, classes.accordionContainer, s.completed ? classes.accordionContainerCompleted : null)}>
            <Accordion className={classes.accordion} elevation="0" expanded={expanded === i} onChange={handleChange(i)}>
              <AccordionSummary
                expandIcon={<img className={classes.expandIcon} alt="expand" src="/icons/expand-more.png"/>}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className={classes.accordionSummary}
              >
                <Grid container alignItems="center" justify="space-between">
                  <Grid item>
                    <Typography variant="h3" className={classes.heading}>{s.name}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.secondaryHeading}>{moment(s.startDate).format('MMMM D, YYYY')}</Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  
                </Typography>
                { !s.completed && (
                  <Button onClick={handleComplete(s)}>Mark Complete</Button>
                )}
                { s.completed && (
                  <Button onClick={handleUndoComplete(s)}>Undo Completion</Button>
                )}
                
              </AccordionDetails>
            </Accordion>
          </div>
        )
      }) }

      <ConnectElements
        selector=".elements"
        elements={[{ from: '.element0', to: '.element1' }]}
      />
    </div>
  )
};

const styles = theme => ({
  secondaryHeading: {
    ...globalStyles.label1
  },
  expandIcon: {
    marginTop: -5
  },
  accordionSummary: {
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 50,
    paddingBottom: 50
  },
  accordion: {
    marginLeft: 40
  },
  accordionContainerLineFirst: {
    '&:after': {
      content: '""',
      position: 'absolute',
      width: 2,
      height: '50%',
      bottom: 0,
      left: 11,
      zIndex: 0,
      background: globalColors.blue
    }
  },
  accordionContainerLine: {
    '&:after': {
      content: '""',
      position: 'absolute',
      width: 2,
      height: 'calc(100% + 24px)',
      bottom: 0,
      top: -24,
      left: 11,
      zIndex: 0,
      background: globalColors.blue
    }
  },
  accordionContainerLineLast: {
    '&:after': {
      content: '""',
      position: 'absolute',
      width: 2,
      height: 'calc(50% + 12px)',
      top: -24,
      left: 11,
      zIndex: 0,
      background: globalColors.blue
    }
  },
  accordionContainer: {
    position: 'relative',
    marginBottom: 24,
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 'calc(50% - 12px)',
      width: 24,
      borderRadius: 12,
      zIndex: 1,
      height: 24,
      background: globalColors.lightestGrey,
      border: `2px solid ${globalColors.blue}`
    }
  },
  accordionContainerCompleted: {
    '&:before': {
      background: globalColors.blue
    }
  },
  teamWrapper: {
    background: globalColors.lightestGrey
  },
  widthWrapperFull: {
    width: '100%',
    ...globalStyles.widthWrapper(theme)
  },
});

export default withRouter(withStyles(styles)(inject('trialStore')(observer(EventStepper))));

import React, { Component } from 'react';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography
} from '@material-ui/core';

import ResourceCard from '../ResourceCard';
import { globalStyles, globalColors } from '../../styles/style-constants';

// import Api from '../../modules/serverless-api';

class InterestedComplete extends Component {

  render() {
    const { classes } = this.props;

    const showEaLanguage = window.location.search === '?ea=true';

    return (
      <div className={classes.patternWrapper}>
        <Grid container direction="row" justify="space-between" style={{ position: 'relative' }}>
          <Grid container justify="center" alignItems="center" className={classNames("HomeHeader")}>
            <Grid item className="HomeHeaderItem" style={{ paddingTop: 24, paddingBottom: 24 }}>
              <a href="https://www.sunstonetherapies.com">
                <img alt="full" src="/Full.png" />
              </a>
              
            </Grid>
          </Grid>
        </Grid>

        <Grid className={classNames(classes.widthWrapper)} direction="column" justify="space-between" alignItems="center">
          <Grid item style={{ paddingTop: 64 }}>
            <Typography variant="h2" style={{ color: 'black' }}>
              Learn more about psychedelic therapy
            </Typography>
            <br/>
            <br/>

            <Grid container spacing={4} direction="row">
              <Grid item sm={12} md={9}>
                <a className={classes.link} href="https://www.cbsnews.com/news/psychedelic-drugs-lsd-active-agent-in-magic-mushrooms-to-treat-addiction-depression-anxiety-60-minutes-2020-08-16/" target="_blank" rel="noopener noreferrer">
                  <ResourceCard
                    image="/Rectangle44.png"
                    title="Psilocybin Sessions: Psychedelics Could Help People With Addiction and Anxiety"
                    description="Anderson Cooper for 60 minutes"
                    tagText={"News"}
                    tagColor={globalColors.darkPink}
                  />
                </a>
              </Grid>
              <Grid item sm={12} md={3}>
                <Grid container spacing={4} direction="column">
                  <Grid item>
                    <a className={classes.link} href="https://www.amazon.com/Change-Your-Mind-Consciousness-Transcendence/dp/0735224153/ref=sr_1_1?dchild=1&keywords=how+to+change+your+mind&qid=1614982492&sr=8-1" target="_blank" rel="noopener noreferrer">
                      <ResourceCard
                        title="How To Change Your Mind"
                        description="Michael Pollan"
                        tagText={"Book"}
                        tagColor={globalColors.orange}
                      />
                    </a>
                  </Grid>
                  <Grid item>
                    <a className={classes.link} href="https://www.tedmed.com/talks/show?id=526825" target="_blank" rel="noopener noreferrer">
                      <ResourceCard
                        title="The science of psilocybin and its use to relieve suffering"
                        description="Roland R. Griffiths, PhD"
                        tagText={"Video"}
                        tagColor={globalColors.blue}
                      />
                    </a>
                  </Grid>
                  <Grid item>
                    <a className={classes.link} href="https://www.amazon.com/Neurons-Nirvana-Understanding-Psychedelic-Medicines/dp/B00J0CHDPE" target="_blank" rel="noopener noreferrer">
                      <ResourceCard
                        title="Neurons to Nirvana"
                        description="Oliver Hockenhull, Mikki Willis"
                        tagText={"Video"}
                        tagColor={globalColors.blue}
                      />
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div style={{ background: 'white', marginTop: 24 }}>
          <Grid container alignItems="center" justify="center" className={classNames(classes.whiteWrapper)}>
            <Grid item align="center" className={classNames(classes.widthWrapperFull, classes.whiteWrapperHalf)}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <a href="https://www.instagram.com/sunstonegrams/" target="_blank" rel="noopener noreferrer">
                        <img alt="full" src="/instagram.png" />
                      </a>
                    </Grid>
                    <Grid item>
                      <a href="https://www.linkedin.com/company/sunstone-therapies/" target="_blank" rel="noopener noreferrer">
                        <img alt="full" src="/linkedin.png" />
                      </a>
                    </Grid>
                    <Grid item>
                      <a href="https://medium.com/@sunstonetherapies" target="_blank" rel="noopener noreferrer">
                        <img alt="full" src="/medium.png" />
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <img alt="full" src="/Full.png" />
                </Grid>
                <Grid item>
                  @2023 Sunstone Therapies
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  panel: {
    padding: '20px',
    borderRadius: '8px'
  },
  thanksTitle: {
    marginTop: 24,
    color: 'white'
  },
  inTouch: {
    marginTop: 48
  },
  emailInput: {
    width: 624
  },
  genderInput: {
    width: 520
  },
  nameInput: {
    width: 233
  },
  label: {
    marginBottom: 20,
    ...globalStyles.label1
  },
  content: {
    paddingTop: '35vh',
    minHeight: '90vh'
  },
  link: {
    color: 'inherit',
    textDecoration: 'none !important'
  },
  patternWrapper: {
    height: '100vh',
    position: 'relative',
    zIndex: 1,
    backgroundColor: theme.globalColors.lightestGrey
  },
  patternWrapper2: {
    position: 'relative',
    paddingTop: 60,
    paddingBottom: 60,
    zIndex: 1,
    color: 'white !important',
    '&:before': {  
      content: '""',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundImage: 'url(https://sunstone-storage-public.s3.amazonaws.com/website-resources/Color+Layer.png)',
      backgroundSize: 'cover',
      position: 'absolute',
      zIndex: -1
    }
  },
  widthWrapperFull: {
    width: '100%',
    ...globalStyles.widthWrapper(theme)
  },
  whiteWrapperHalf: {
    background: globalColors.white,
    paddingTop: 24,
    paddingBottom: 24
  },
  widthWrapper: globalStyles.widthWrapper(theme)
});

export default withStyles(styles)(InterestedComplete);
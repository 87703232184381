import {
  Grid,
  Typography
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

function FormHeader({ classes, text }) {
  return (
    <Grid container direction="column" justify="flex-start" className={classes.header}>
      <Typography variant="h2">{text}</Typography>
    </Grid>
  )
}

const styles = theme => ({
  header: {
    marginBottom: 24,
    width: '100%'
  }
});

export default withStyles(styles)(FormHeader);

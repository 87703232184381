/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { observer, inject } from 'mobx-react';

import { withStyles } from '@material-ui/core/styles';
import { Checkbox, Grid, Typography } from '@material-ui/core';

import NameFormatter from '../formatters/Name';

import HeaderCell from '../../core/formatters/HeaderCell';
import DataGridWithHeader from '../../core/DataGrid';
import CustomLink from '../../core/CustomLink';

import { globalColors } from '../../../styles/style-constants';

import { usePagingAndSorting } from '../../../hooks';

const styles = theme => ({
  wrapper: {
    marginBottom: theme.spacing(5),
  },
});

function UserListing({
  classes,
  wrapperClass,
  isLoading,
  data,
  totalRows,
  fetchData,
  onUserRemoved,
  onEditUser,
  
  onSelectUser,
  canSelectUsers
}) {
  const [pageIndex, onPageChange, sortInfo, onSortChange, onSortReset] = usePagingAndSorting({
    pageIndex: 0,
    sortInfo: {
      sortKey: 'name',
      isAscOrder: true,
    }
  });

  useEffect(() => {
    fetchData({
      pageIndex,
      pageSize: 10,
      ...sortInfo,
    })
  }, [pageIndex, sortInfo, fetchData]);

  const columns = useMemo(() => [
    {
      Header: () => {
        return (
          <HeaderCell
            title="NAME"
            sortKey='name'
            sortingApplied={sortInfo.sortKey === 'name'}
            isAscOrder={sortInfo.isAscOrder}
            onSortChange={onSortChange}
            onSortReset={onSortReset}
            alignToEdge
          />
        )
      },
      accessor: 'name',
      width: '280px',
      Cell: ({ row: { original: row} }) => {
        return (
          <CustomLink to={`/users/${row.id}`}onClick={onEditUser ? onEditUser.bind(this, row) : null} className={classes.cursor}>
            <Typography align="left" style={{color: globalColors.blue}}>{row.firstName} {row.lastName}</Typography>
          </CustomLink>
        );
      }
    },
    {
      Header: () => {
        return (
          <HeaderCell title="Email" />
        )
      },
      width: '150px',
      accessor: 'email',
      Cell: ({ row: { original: row} }) => {
        return (
          <Grid container justify="center" alignItems="center">
            {row.email}
          </Grid>
        );
      }
    },
    {
      Header: () => {
        return (
          <HeaderCell title="ROLE" />
        )
      },
      width: '150px',
      accessor: 'role',
      Cell: ({ row: { original: row} }) => {
        return (
          <div>{row.role}</div>
        );
      }
    },
    {
      Header: () => {
        return (
          <HeaderCell title="PERMISSIONS" />
        )
      },
      width: '150px',
      accessor: 'permissions',
      Cell: ({ row: { original: row} }) => {
        return (
          <div>{Object.keys(row.permissions || {})}</div>
        );
      }
    },
    {
      Header: () => {
        return (
          <HeaderCell title="ACTIONS" />
        )
      },
      width: '75px',
      accessor: 'status',
      Cell: ({ row: { original: row} }) => {
        return (
          <Grid container justify="center">
            <Grid item>
              <span onClick={onUserRemoved ? onUserRemoved.bind(this, row) : null} className={classes.cursor}>Deactivate</span>
            </Grid>
          </Grid>
        );
      }
    },
  ], [sortInfo, onSortChange, onSortReset]);

  if (canSelectUsers) {
    columns.splice(0, 0, {
      Header: () => {
        return (
          <HeaderCell
            title=""
            alignToEdge
          />
        )
      },
      accessor: 'select',
      width: '30px',
      Cell: ({ row: { original: row} }) => {
        return (
          <Checkbox onClick={onSelectUser.bind(this, row)} />
        );
      }
    });

    columns.splice(columns.length - 3, 3);
  }

  return (
    <DataGridWithHeader
      isLoading={isLoading}
      wrapperClass={classNames(classes.wrapper, wrapperClass)}
      height={700}
      width="100%"
      rowHeight={100}
      columns={columns}
      rows={data}
      pageSize={50}
      totalRows={totalRows}
      onPageChange={onPageChange}
      noBorder
    />
  );
}

export default withStyles(styles)(inject('userStore')(observer((UserListing))));

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { remCalc } from '../../util/style-util';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = ({ classes }) => {
  return (
    <div className={classes.container}>
      <CircularProgress
        className={classes.inProgress}
        size={80}
        color="inherit"
      />
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    fontSize: remCalc(65),
  },
  inProgress: {
    margin: remCalc(32)
  }
};

export default withStyles(styles)(Loading);

import React from 'react';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import CustomLink from '../../core/CustomLink';


const styles = theme => ({
  wrapper: {
    lineHeight: "24px",
    textAlign: "left",
    paddingLeft: theme.spacing(3),
  },
});

class NameFormatter extends React.PureComponent {
  render() {
    const {
      classes,
      wrapperClass,
      name,
      id
    } = this.props;

    return (
      <Grid
        container
        direction="column"
        className={classNames(classes.wrapper, wrapperClass)}
      >
        <Grid item>
          <CustomLink to={`/trials/${id}`}>
            {name}
          </CustomLink>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(NameFormatter);
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import NameFormatter from '../formatters/Name';
import DateFormatter from '../formatters/Date';
import TrialStatusFormatter from '../formatters/TrialStatus';

import HeaderCell from '../../core/formatters/HeaderCell';
import DataGridWithHeader from '../../core/DataGrid';

import { usePagingAndSorting } from '../../../hooks';

const styles = theme => ({
  wrapper: {
    marginBottom: theme.spacing(5),
  },
});

function TrialListing({
  classes,
  wrapperClass,
  isLoading,
  data,
  totalRows,
  fetchData,
}) {
  const [pageIndex, onPageChange, sortInfo, onSortChange, onSortReset] = usePagingAndSorting({
    pageIndex: 0,
    sortInfo: {
      sortKey: 'memberName',
      isAscOrder: true,
    }
  });

  useEffect(() => {
    fetchData({
      pageIndex,
      pageSize: 10,
      ...sortInfo,
    })
  }, [pageIndex, sortInfo, fetchData]);

  const columns = useMemo(() => [
    {
      Header: () => {
        return (
          <HeaderCell
            title="TRIAL"
            sortKey='memberName'
            sortingApplied={sortInfo.sortKey === 'name'}
            isAscOrder={sortInfo.isAscOrder}
            onSortChange={onSortChange}
            onSortReset={onSortReset}
            alignToEdge
          />
        )
      },
      accessor: 'name',
      width: '280px',
      Cell: ({ row: { original: row} }) => {
        return (
          <NameFormatter
            id={row.id}
            name={row.name}
          />
        );
      }
    },
    {
      Header: () => {
        return (
          <HeaderCell title="DATE" />
        )
      },
      width: '150px',
      accessor: 'tags',
      Cell: ({ row: { original: row} }) => {
        return (
          <Grid container justify="center" alignItems="center">
            <Grid item>
              <DateFormatter
                date={row.startDate}
              /> 
            </Grid>
            <Grid item style={{ paddingLeft: 8, paddingRight: 8 }}>
              -
            </Grid>
            <Grid item>
              <DateFormatter
                date={row.endDate}
              /> 
            </Grid>
          </Grid>
        );
      }
    },
    {
      Header: () => {
        return (
          <HeaderCell title="LOCATION" />
        )
      },
      width: '150px',
      accessor: 'location',
      Cell: ({ row: { original: row} }) => {
        console.log(row.Sites);
        return (
          <Grid container justify="center" alignItems="center">
            { (row.Sites || []).map(s => s.name).join(', ')}
          </Grid>
        );
      }
    },
    {
      Header: () => {
        return (
          <HeaderCell title="STATUS" />
        )
      },
      width: '280px',
      accessor: 'status',
      Cell: ({ row: { original: row} }) => {
        return (
          <Grid container justify="center" alignItems="center">
            <TrialStatusFormatter
              status={row.status}
            />
          </Grid>
        );
      }
    },
  ], [sortInfo, onSortChange, onSortReset]);

  return (
    <DataGridWithHeader
      isLoading={isLoading}
      wrapperClass={classNames(classes.wrapper, wrapperClass)}
      height={700}
      width="100%"
      rowHeight={100}
      columns={columns}
      rows={data}
      pageSize={50}
      totalRows={totalRows}
      onPageChange={onPageChange}
      noBorder
    />
  );
}

export default withStyles(styles)(TrialListing);

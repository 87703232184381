/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import NameFormatter from '../formatters/Name';

import HeaderCell from '../../core/formatters/HeaderCell';
import DataGridWithHeader from '../../core/DataGrid';

import { usePagingAndSorting } from '../../../hooks';

const styles = theme => ({
  wrapper: {
    marginBottom: theme.spacing(5),
  },
});

function ParticipantListing({
  classes,
  wrapperClass,
  isLoading,
  data,
  totalRows,
  fetchData,
  onParticipantRemoved
}) {
  const [pageIndex, onPageChange, sortInfo, onSortChange, onSortReset] = usePagingAndSorting({
    pageIndex: 0,
    sortInfo: {
      sortKey: 'memberName',
      isAscOrder: true,
    }
  });

  useEffect(() => {
    fetchData({
      pageIndex,
      pageSize: 10,
      ...sortInfo,
    })
  }, [pageIndex, sortInfo, fetchData]);

  const columns = useMemo(() => [
    {
      Header: () => {
        return (
          <HeaderCell
            title="NAME"
            sortKey='memberName'
            sortingApplied={sortInfo.sortKey === 'name'}
            isAscOrder={sortInfo.isAscOrder}
            onSortChange={onSortChange}
            onSortReset={onSortReset}
            alignToEdge
          />
        )
      },
      accessor: 'name',
      width: '280px',
      Cell: ({ row: { original: row} }) => {
        return (
          <Typography align="left">{row.firstName} {row.lastName}</Typography>
        );
      }
    },
    {
      Header: () => {
        return (
          <HeaderCell title="ID #" />
        )
      },
      width: '150px',
      accessor: 'tags',
      Cell: ({ row: { original: row} }) => {
        return (
          <Grid container justify="center" alignItems="center">
            {row.id}
          </Grid>
        );
      }
    },
    {
      Header: () => {
        return (
          <HeaderCell title="PHASE" />
        )
      },
      width: '150px',
      accessor: 'location',
      Cell: ({ row: { original: row} }) => {
        return (
          <div></div>
        );
      }
    },
    {
      Header: () => {
        return (
          <HeaderCell title="ACTIONS" />
        )
      },
      width: '280px',
      accessor: 'status',
      Cell: ({ row: { original: row} }) => {
        return (
          <div onClick={onParticipantRemoved.bind(this, row)}>
            Remove
          </div>
        );
      }
    },
  ], [sortInfo, onSortChange, onSortReset]);

  return (
    <DataGridWithHeader
      isLoading={isLoading}
      wrapperClass={classNames(classes.wrapper, wrapperClass)}
      height={700}
      width="100%"
      rowHeight={100}
      columns={columns}
      rows={data}
      pageSize={50}
      totalRows={totalRows}
      onPageChange={onPageChange}
      noBorder
    />
  );
}

export default withStyles(styles)(ParticipantListing);

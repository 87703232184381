import React, { Fragment } from 'react';
import clsx from 'clsx';
import classNames from 'classnames';
import { observer, inject } from 'mobx-react';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import HomeIcon from '@material-ui/icons/Home';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import PeopleIcon from '@material-ui/icons/People';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

import { Link, withRouter } from 'react-router-dom';

import { Grid, Typography } from '@material-ui/core';

import { globalColors, globalStyles } from '../styles/style-constants';

const drawerWidth = 272;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: globalColors.blue20
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
    backgroundColor: globalColors.blue20
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  active: {
    backgroundColor: `${globalColors.blue} !important`,
    color: globalColors.white
  },
  activeIcon: {
    color: 'white !important' 
  },
  button: {
    ...globalStyles.button
  },
}));

const ApplicationDrawer = ({ userStore }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    console.log('leave');
    setOpen(false);
  };

  const handleMouseEnter = () => {
    setTimeout(() => {
      handleDrawerOpen();
    }, 250);
  }

  const dashboardActive = window.location.pathname === '/';
  const scheduleActive = window.location.pathname === '/schedule';
  const cohortActive = window.location.pathname === '/cohort';
  const messageActive = window.location.pathname === '/message';
  const insightsActive = window.location.pathname === '/insights';

  const usersActive = window.location.pathname.indexOf('/users') === 0;
  const trialsActive = window.location.pathname.indexOf('/trials') === 0;

  return (
    <div className={classes.root}>
      <Drawer
        variant="permanent"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleDrawerClose}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Grid container alignItems="center" justify="center">
          <Grid item style={{height: 180}}>
          <img alt="logo" style={{ marginTop: 40, height: open ? null : 52, width: open ? null : 52, marginBottom: open ? 14 : 82 }} onClick={open ? handleDrawerClose : handleDrawerOpen} src={open ? "/Full.png" : "/Mark.png"} />
          </Grid>
        </Grid>
        
        <List>
          <Link style={{textDecoration: 'none'}} to={{
            pathname: "/",
            state: { fromDashboard: true }
          }}>
            <ListItem button className={classNames(dashboardActive ? classes.active : classes.inactive)}>
              <ListItemIcon style={{ paddingLeft: 8 }}><HomeIcon className={classNames(dashboardActive ? classes.activeIcon : null)} /></ListItemIcon>
              <ListItemText primary={<Typography className={classes.button} style={{ color: dashboardActive ? globalColors.white : globalColors.blue }}>Dashboard</Typography>} />
            </ListItem>
          </Link>
          { userStore.isSuperAdmin && (
            <Fragment>
              {/* <Link style={{textDecoration: 'none'}} to={{
                pathname: "/trials",
                state: { fromDashboard: true }
              }}>
                <ListItem button className={classNames(trialsActive ? classes.active : null)}>
                  <ListItemIcon style={{ paddingLeft: 8 }}><CalendarTodayIcon className={classNames(trialsActive ? classes.activeIcon : null)}/></ListItemIcon>
                  <ListItemText primary={<Typography className={classes.button} style={{ color: trialsActive ? globalColors.white : globalColors.blue }}>Trials</Typography>} />
                </ListItem>
              </Link> */}
              <Link style={{textDecoration: 'none'}} to={{
                pathname: "/users",
                state: { fromDashboard: true }
              }}>
                <ListItem button className={classNames(usersActive ? classes.active : null)}>
                  <ListItemIcon style={{ paddingLeft: 8 }}  className={classNames(usersActive ? classes.activeIcon : null)}><PeopleIcon /></ListItemIcon>
                  <ListItemText primary={<Typography className={classes.button} style={{ color: usersActive ? globalColors.white : globalColors.blue }}>Users</Typography>} />
                </ListItem>
              </Link>
            </Fragment>
          )}
          { !userStore.isSuperAdmin && (
            <Fragment>
              <Link style={{textDecoration: 'none'}} to={{
                pathname: "/schedule",
                state: { fromDashboard: true }
              }}>
                <ListItem button className={classNames(scheduleActive ? classes.active : null)}>
                  <ListItemIcon style={{ paddingLeft: 8 }}><CalendarTodayIcon className={classNames(scheduleActive ? classes.activeIcon : null)}/></ListItemIcon>
                  <ListItemText primary={<Typography className={classes.button} style={{ color: scheduleActive ? globalColors.white : globalColors.blue }}>Schedule</Typography>} />
                </ListItem>
              </Link>
              <Link style={{textDecoration: 'none'}} to={{
                pathname: "/cohort",
                state: { fromDashboard: true }
              }}>
                <ListItem button className={classNames(cohortActive ? classes.active : null)}>
                  <ListItemIcon style={{ paddingLeft: 8 }}  className={classNames(cohortActive ? classes.activeIcon : null)}><PeopleIcon /></ListItemIcon>
                  <ListItemText primary={<Typography className={classes.button} style={{ color: cohortActive ? globalColors.white : globalColors.blue }}>Cohort</Typography>} />
                </ListItem>
              </Link>
              <Link style={{textDecoration: 'none'}} to={{
                pathname: "/message",
                state: { fromDashboard: true }
              }}>
                <ListItem button className={classNames(messageActive ? classes.active : null)}>
                  <ListItemIcon style={{ paddingLeft: 8 }} className={classNames(messageActive ? classes.activeIcon : null)}><ChatBubbleIcon /></ListItemIcon>
                  <ListItemText primary={<Typography className={classes.button} style={{ color: messageActive ? globalColors.white : globalColors.blue }}>Message</Typography>} />
                </ListItem>
              </Link>
              <Link style={{textDecoration: 'none'}} to={{
                pathname: "/insights",
                state: { fromDashboard: true }
              }}>
                <ListItem button className={classNames(insightsActive ? classes.active : null)}>
                  <ListItemIcon style={{ paddingLeft: 8 }} className={classNames(insightsActive ? classes.activeIcon : null)}><TrendingUpIcon /></ListItemIcon>
                  <ListItemText primary={<Typography className={classes.button} style={{ color: insightsActive ? globalColors.white : globalColors.blue }}>Insights</Typography>} />
                </ListItem>
              </Link>
            </Fragment>
          )}
        </List>
      </Drawer>
    </div>
  );
}

export default withRouter(inject('userStore')(observer(ApplicationDrawer)));

import React from 'react';
// import classNames from 'classnames';

import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import { globalColors, globalStyles } from './styles/style-constants';

// import EventStepper from './components/trial/participant/EventStepper';
import Loading from './components/core/Loading';

import ParticipantHome from './components/trial/participant/Home';
import SuperAdminHome from './components/superuser/Home';
import TherapistHome from './components/trial/therapist/Home';

// import {
//   Grid
// } from '@material-ui/core';

// import Header from './components/trial/common/Header';
// import InfoPanel from './components/trial/common/InfoPanel';

class LandingPage extends React.Component {
  componentDidMount() {
    this.props.trialStore.fetchCurrentTrial();
  }
  
  render() {
  const { userStore, trialStore: { currentTrial, loadingTrial } } = this.props;

    if (!userStore.user || loadingTrial) {
      return <Loading />;
    }

    const { user: { role } } = userStore;

    if (role === 'superadmin') {
      return <SuperAdminHome />
    }

    if (!loadingTrial && !currentTrial) {
      return (
        <div>There was an error loading your enrollment.</div>
      )
    }

    if (currentTrial.enrollment.role === 'participant') {
      return <ParticipantHome />
    } else if (currentTrial.enrollment.role === 'therapist') {
      return <TherapistHome />
    } else {
      return (
        <div>There was an error loading your enrollment.</div>
      )
    }
  }
}

const styles = theme => ({
  eventWrapper: {
    marginTop: -24
  },
  teamWrapper: {
    background: globalColors.lightestGrey
  },
  widthWrapperFull: {
    width: '100%',
    ...globalStyles.widthWrapper(theme)
  },
});

export default withRouter(withStyles(styles)(inject('trialStore', 'userStore')(observer(LandingPage))));
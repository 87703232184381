import React, { useEffect } from 'react';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import LastPageIcon from '@material-ui/icons/LastPage';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useTable, usePagination } from 'react-table';

const TableStyle = theme => ({
  tableWrapper: {
    overflow: "auto",
    position: "relative",
    border: "1px solid #dcdcdc",
  },
  tableWrapperNoBorder: {
    border: 0,
  },
  table: {
    tableLayout: "fixed",
    borderSpacing: "0",
    width: "100%",
  },
  thead: {
    fontSize: "15px",
    fontWeight: "bold",
    backgroundColor: "#fafafa",
  },
  theadNestedTable: {
    width: "100%",
  },
  tbodyCell: {
    textAlign: "center",
    verticalAlign: "middle",
    borderRight: "1px solid #dcdcdc",
    borderBottom: "1px solid #dcdcdc",
    fontSize: "16px",
    "&:last-child": {
      borderRight: "0",
    }
  },
  tbodyCellNoHorizontalBorder: {
    borderRight: 0,
  },
  noDataMessageWrapper: {
    padding: `${theme.spacing(3)}px 0`,
  },
  paginationWrapper: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
  }
});

const TableHeaderStyle = theme => ({
  theadTh: {
    padding: 0,
    backgroundColor: "#fafafa",
    borderBottom: "1px solid #dcdcdc",
    borderRight: "1px solid #dcdcdc",
    position: "sticky",
    top: "0",
    zIndex: "2",
    "&:first-child": {
      left: "0",
      zIndex: "3",
    },
    "&:last-child": {
      borderRight: "0",
    }
  },
  theadThNoHorizontalBorder: {
    borderBottom: 0,
    borderRight: 0,
  },
  theadTr: {
    borderBottom: "1px solid #dcdcdc",
  },
  nestedTable: {
    maxHeight: "185px",
    tableLayout: "fixed",
    width: "100%",
    borderCollapse: "collapse",
    backgroundColor: "#fafafa",
  },
  nestedTableTh: {
    padding: 0,
    borderRight: "1px solid #dcdcdc",
    "&:last-child": {
      borderRight: 0,
    },
  },
});

function HeaderCell({ classes, column }) {
  if (column.columns) {
    return (
      <table className={classNames(classes.nestedTable, column.nestedHeaderTableClassName)}>
        <tr className={classes.theadTr}>
          <th {...column.getHeaderProps()}>{column.render('Header')}</th>
        </tr>
        <tr>
          {
            column.columns.map((nestedColumn) => (
              <th style={{width: nestedColumn.width}} className={classes.nestedTableTh}>
                <StyledHeaderCell column={nestedColumn} />
              </th>
            ))
          }
        </tr>
      </table>
    )
  } else {
    return (
      column.render('Header')
    )
  }
}

const StyledHeaderCell = withStyles(TableHeaderStyle)(HeaderCell);

function TableHeader({ classes, headerGroups, noBorder }) {
  const headerGroupLast = headerGroups[headerGroups.length - 1];
  const addedCols = {};
  const headersToRender = [];

  headerGroupLast.headers.forEach((header) => {
    if (header.parent) {
      let current = header;

      while (current.parent) {
        current = current.parent;
      }

      if (!addedCols[current.id]) {
        headersToRender.push(headerGroups[0].headers.filter((header) => header.originalId === current.id)[0])
        addedCols[current.id] = true;
      }
    } else {
      headersToRender.push(header);
    }
  });

  return headersToRender.map((column) => (
    <th style={{width: column.width}} className={classNames(classes.theadTh, {[classes.theadThNoHorizontalBorder]: noBorder})} {...column.getHeaderProps()}>
      <StyledHeaderCell column={column} />
    </th>
  ));
}

const StyledTableHeader = withStyles(TableHeaderStyle)(TableHeader);

function Table({
  classes,
  isLoading,
  columns,
  data,
  maxHeight,
  rowHeight,
  totalRows,
  onPageChange,
  noBorder,
}) {
  const DEFAULT_PAGE_SIZE = 10;
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    pageOptions,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: DEFAULT_PAGE_SIZE},
      pageCount: Math.ceil(totalRows / DEFAULT_PAGE_SIZE),
      manualPagination: true,
    },
    usePagination
  );

  useEffect(() => {
    typeof onPageChange === "function" && onPageChange(pageIndex)
  }, [pageIndex, onPageChange]);

  let rowsToRender =  null;
  let noDataMessage = null;
  if (rows.length) {
    rowsToRender = (
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
      
          return (
            <tr 
              style={{height: rowHeight}}
              {...row.getRowProps()}
            >
              {row.cells.map(cell => {
                return (
                  <td
                    className={classNames(classes.tbodyCell, cell.column.cellClassName, {[classes.tbodyCellNoHorizontalBorder]: noBorder})}
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })
      }
      </tbody>
    );
  } else {
    noDataMessage = (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classNames(classes.noDataMessageWrapper)}
      >
        <Grid 
          item
          className={classNames(classes.noDataMessageText)}
        >
          { isLoading ? "Please wait..." : "There is no data to display." }
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <div
        style={{width: "100%", maxHeight}}
        className={classNames(classes.tableWrapper, { [classes.tableWrapperNoBorder]: noBorder})}
      >
        <table
          className={classes.table}
          {...getTableProps()}
        >
          <thead className={classes.thead}>
            <tr>
              <StyledTableHeader
                headerGroups={headerGroups}
                noBorder={noBorder}
              />
            </tr>
          </thead>
          {rowsToRender}
        </table>
        {noDataMessage}
      </div>
      <Grid
        container
        justify="flex-end"
        alignItems="center"
        className={classes.paginationWrapper}
      >
        <IconButton
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <NavigateBeforeIcon />
        </IconButton>
        <IconButton
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          <NavigateNextIcon />
        </IconButton>
        <IconButton
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          <LastPageIcon />
        </IconButton>
        <span>
          Page {pageIndex + 1} of {pageOptions.length}
        </span>
      </Grid>
    </>
  )
}

const StyledTable = withStyles(TableStyle)(Table);

const DataGridStyles = theme => ({
  titleWrapper: {
    fontSize: "24px",
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  tableWrapper: {
    position: "relative",
  },
  tableLoaderWrapper: {
    height: "100%",
    position: "absolute",
    zIndex: 10,
    backgroundColor: "#3333330a",
  }
  
});

function DataGridWithHeader ({
  classes,
  wrapperClass,
  isLoading,
  title,
  maxHeight,
  columns,
  rows,
  rowHeight,
  totalRows,
  onPageChange,
  noBorder
}) {
  return (
    <Grid
      container
      direction="column"
      className={wrapperClass}
    >
      <Grid
        item
        className={classes.titleWrapper}
      >
        {title}
      </Grid>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.tableWrapper}
      >
        <StyledTable
          isLoading={isLoading}
          maxHeight={maxHeight}
          columns={columns}
          data={rows}
          totalRows={totalRows}
          rowHeight={rowHeight}
          onPageChange={onPageChange}
          noBorder={noBorder}
        />
        {
          isLoading ? (
            <Grid
              container
              justify="center"
              alignItems="center"  
              className={classes.tableLoaderWrapper}
            >
              <CircularProgress />
            </Grid>
          ) : null
        }
      </Grid>
    </Grid>
  );
}


export default withStyles(DataGridStyles)(DataGridWithHeader);
import {
  Grid,
  LinearProgress
} from '@material-ui/core';

import Button from './Button';
import { withStyles } from '@material-ui/core/styles';

const progressStyles = theme => ({
  colorPrimary: {
    backgroundColor: theme.palette.progressColor.colorPrimary
  },
  barColorPrimary: {
    backgroundColor: theme.palette.progressColor.barColorPrimary
  }
});

const StyledLinearProgress = withStyles(progressStyles)(LinearProgress);

function ProgressPanel({
  classes,
  onNext,
  onPrevious,
  onComplete,
  percentComplete,
  isFirstStep,
  nextLabel = 'Next Question',
  submitLabel,
  children = {}
}) {
  return (
    <Grid container spacing={3} style={{ width: '100%' }} fullWidth direction="row" alignItems="center" justify="space-between" className={classes.panel}>
      <Grid item>
        <Button noPadding={true} disabled={isFirstStep} onClick={onPrevious}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
        </Button>
      </Grid>
      <Grid item xs>
        <StyledLinearProgress
          variant="determinate"
          value={percentComplete}
        />
      </Grid>
      <Grid item>
        { percentComplete === 100 && (
          <Button onClick={onComplete}>
            <span>{submitLabel}</span>
          </Button>
        )}
        { percentComplete !== 100 && (
          <Button onClick={onNext}>
            <span>{nextLabel}</span>
            <span style={{ paddingLeft: 8 }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
            </span>
          </Button>
        )}
      </Grid>
    </Grid>
  )
}

const styles = theme => ({
  panel: {
    padding: '0px 0',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.globalColors.white
  },
  progress: {
    colorPrimary: 'blue',
    indeterminate: 'blue'
  }
});

export default withStyles(styles)(ProgressPanel);
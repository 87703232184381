import { observer, inject } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import { globalColors, globalStyles } from '../styles/style-constants';
import classNames from 'classnames';

import {
  Grid,
  Typography
} from '@material-ui/core';

const ApplicationHeader = ({ classes, userStore }) => (
  <Grid container className={classNames(classes.headerWrapper)}>
    <Grid item xs={12}>
      <Grid container justify="space-between" spacing={3} className={classes.headerPadding}>
        <Grid item>

        </Grid>
        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <Link
                to={{
                  pathname: "/profile",
                  state: { fromDashboard: true }
                }}
              >
                <img alt="logo" src="/icons/user-red.png"/>
              </Link>
            </Grid>
            <Grid item>
              <Typography className={classes.headerText}>{userStore.fullName}</Typography>
            </Grid>
            <Grid item style={{ marginTop: -4 }}>
              <img alt="logo" src="/icons/expand-more.png"/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const styles = theme => ({
  headerWrapper: {
    borderBottom: `1px solid ${globalColors.lightGrey}`,
    marginBottom: 24
  },
  headerPadding: {
    paddingTop: 24,
    paddingBottom: 24,
    paddingRight: 24
  },
  headerText: {

  },
  widthWrapperFull: {
    width: '100%',
    ...globalStyles.widthWrapper(theme)
  },
});

export default withRouter(withStyles(styles)(inject('userStore')(observer(ApplicationHeader))));


import classNames from 'classnames';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import {
	CognitoUserPool,
	CognitoUserAttribute
} from 'amazon-cognito-identity-js';

import config from '../../config';

import { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
  Grid,
  // Typography
} from '@material-ui/core';

import FormHeader from '../FormHeader';
import InputField from '../InputField';
import Button from '../Button';

import { globalStyles } from '../../styles/style-constants';

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      firstName: '',
      lastName: '',
      password: ''
    };
  }

  register() {
    const { userStore } = this.props;

    const {
      UserPoolId,
      ClientId
    } = config;

    var userPool = new CognitoUserPool({
      UserPoolId,
      ClientId
    });

    var attributeList = [];

    var dataEmail = {
      Name: 'email',
      Value: this.state.email,
    };

    var attributeEmail = new CognitoUserAttribute(dataEmail);

    attributeList.push(attributeEmail);

    userPool.signUp(this.state.email, this.state.password, attributeList, null, async (
      err,
      result
    ) => {
      if (err) {
        alert(err.message || JSON.stringify(err));
        return;
      }

      var cognitoUser = result.user;
      console.log(cognitoUser);
      console.log('user name is ' + cognitoUser.getUsername());

      await userStore.register({
        email: this.state.email,
        username: cognitoUser.getUsername(),
        firstName: this.state.firstName,
        lastName: this.state.lastName
      });

      window.location.href = "/verify-account?registered=true";
    });
  }

  render() {
    const { classes } = this.props;
    const { email, lastName, firstName, password } = this.state;

    return (
      <div className={classes.patternWrapper}>
        <Grid className={classNames(classes.widthWrapper, classes.panel)} direction="column" justify="center" alignItems="center">
          <Grid item xs={12}>
            <Grid container spacing={3} alignItems="center" justify="center" className={classes.content}> 
              <Grid item>
                <Grid direction="column" spacing={3} container alignItems="center" justify="center">
                  <Grid item>
                    <FormHeader text="Register" />
                  </Grid>
                  <Grid item style={{ marginTop: -24 }}>
                    <Grid container spacing={3} direction="row">
                      <Grid item className={classes.nameInput}>
                        <InputField collapsed={true} labelText={"First Name"}
                          onChange={e => this.setState({ firstName: e.target.value })}
                          inputProps={{
                            value: firstName,
                            id: 'first-name',
                            name: 'first-name'
                          }}
                        />
                      </Grid>
                      <Grid item className={classes.nameInput}>
                        <InputField collapsed={true} labelText={"Last Name"} 
                          onChange={e => this.setState({ lastName: e.target.value })}
                          inputProps={{
                            value: lastName,
                            id: 'last-name',
                            name: 'last-name'
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid container spacing={3} direction="row">
                      <Grid item className={classes.nameInput}>
                        <InputField collapsed={true} labelText={"Email Address"}
                          onChange={e => this.setState({ email: e.target.value })}
                          inputProps={{
                            value: email,
                            name: 'email',
                            id: 'email'
                          }}
                        />
                      </Grid>
                      <Grid item className={classes.nameInput}>
                        <InputField collapsed={true} labelText={"Password"} 
                          onChange={e => this.setState({ password: e.target.value })}
                          inputProps={{
                            type: 'password',
                            value: password
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid container spacing={1} direction="column" justify="space-between" alignItems="center">
                      <Grid item>
                        <Button onClick={this.register.bind(this)}>
                        <span>Register</span>
                      </Button>
                      </Grid>
                      <Grid item>
                        <br/>
                        <br/>
                        <a href="/login">Already have an account? Log in</a>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const styles = theme => ({
  content: {
    minHeight: 'calc(100vh - 120px)'
  },
  patternWrapper: {
    height: '100vh',
    position: 'relative',
    zIndex: 1,
    backgroundColor: theme.globalColors.lightestGrey,
    '&:before': {  
      content: '""',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundImage: 'url(https://sunstone-storage-public.s3.amazonaws.com/website-resources/Vector.png)',
      position: 'absolute',
      opacity: 0.25,
      zIndex: -1
    }
  },
  widthWrapper: globalStyles.widthWrapper(theme)
});

export default withRouter(withStyles(styles)(inject('userStore')(observer(Register))));

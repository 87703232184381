// @flow
import axios from 'axios';
import config from '../config';

const POST = 'post';
const DELETE = 'delete';


const initApi = () => {
  const api = axios.create();
  api.defaults.baseURL = config.serverlessApiEndpoint;

  return api;
};

const axiosApi = initApi();

const getBody = (verb: string, body?: any): any => {
  // https://github.com/axios/axios/issues/897#issuecomment-343715381
  if (body !== undefined && verb === DELETE) {
    return { data: body };
  }

  return body;
};

const execute = async (verb: string, url: string, body?: any, additionalConfig: any) => {
  const config = {
    url,
    method: verb,
    data: getBody(verb, body),
    ...additionalConfig
  };
  const { status, headers, data } = await axiosApi.request(config);

  return {
    status,
    headers,
    data
  };
};

class SunstoneServerlessApi {
  async submitInterestedForm(body) {
    return execute(POST, '/interested', body);
  }

  async submitInterestedTherapistForm(body) {
    return execute(POST, '/interested-therapist', body);
  }

  async submitCenterTherapistForm(body) {
    return execute(POST, '/interested-center', body);
  }
}

export default new SunstoneServerlessApi();

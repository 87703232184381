import React from "react";
import { capitalize } from "lodash";

import classNames from 'classnames';
import { observer, inject } from 'mobx-react';
import { Redirect, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import { globalColors, globalStyles } from './styles/style-constants';

import {
  Grid,
  Typography
} from '@material-ui/core';

import InfoPanel from './components/trial/common/InfoPanel';
import Loading from './components/core/Loading';
import Button from './components/Button';
import CustomLink from './components/core/CustomLink';
import ApplicationHeader from './components/ApplicationHeader';
import ApplicationMenu from './components/ApplicationMenu';
import ParticipantListing from './components/superuser/participants/ParticipantListing';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

import InputField from './components/InputField';
import SelectField from './components/SelectField';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  async componentDidMount() {
    this.props.siteStore.getSites();

    if (this.props.match.params.userId !== 'new') {
      await this.props.userStore.getUser(this.props.match.params.userId);
      this.setState({
        user: this.props.userStore.currentUser || {},
        isNewUser: false
      });
    } else {
      this.setState({ 
        user: {
          Sites: []
        },
        isNewUser: true 
      });
    }
  }

  async submit() {
    if (this.state.user.id) {
      await this.props.userStore.updateUser(this.state.user);
    } else {
      await this.props.userStore.createUser(this.state.user);
    }

    this.setState({ redirect: true });
    
  }

  handleSiteChange(e) {
    const { user } = this.state;
    const { siteStore: { sites } } = this.props;

    user.Sites = e.target.value.map(siteId => sites.find(s => s.id === siteId));
    this.setState({ user });
  }

  handleRole(e) {
    this.setState(prevState => prevState.user.role = e.target.value);
  }

  render() {
    const { classes, siteStore: { sites, loadingSites }, userStore, userStore: { loadingUser, currentUser } } = this.props;
    const { isNewUser, redirect, user } = this.state;

    if (!user || loadingUser || loadingSites) {
      return <Loading />
    }

    if (redirect) {
       return <Redirect to='/users'/>;
     }

    return (
      <Grid container spacing={3} justify="flex-end" direction="column" className={classNames(classes.teamWrapper)}>
        <ApplicationMenu />
        <Grid item>
          <ApplicationHeader />
        </Grid>

        <Grid container direction="column" className={classNames(classes.headerWrapper)}>
          <Grid item className={classNames(classes.widthWrapperFull)}>
            <Grid item>
              <CustomLink to="/users">
                <Typography className={classes.buttonBack}>Back to all users</Typography>
              </CustomLink>
            </Grid>

            <Grid item>
              <Grid container alignItems="center" justify="space-between" style={{marginTop: 32}}>
                <Grid item>
                  <Typography className={classes.h2}>{ isNewUser ? 'Create New User' : `Edit User` }</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item style={{ marginTop: 24 }}>
              <Grid container spacing={3} justify="center" direction="column">
                <Grid item>
                  <InputField collapsed={false} labelText={"First Name"} 
                    onChange={e => this.setState(prevState => prevState.user.firstName = e.target.value)}
                    inputProps={{
                      value: user.firstName
                    }}
                  />
                </Grid>
                <Grid item>
                  <InputField collapsed={false} labelText={"Last Name"} 
                    onChange={e => this.setState(prevState => prevState.user.lastName = e.target.value)}
                    inputProps={{
                      value: user.lastName
                    }}
                  />
                </Grid>
                <Grid item>
                  <InputField collapsed={false} labelText={"Email Address"} 
                    onChange={e => this.setState(prevState => prevState.user.email = e.target.value)}
                    inputProps={{
                      value: user.email
                    }}
                  />
                </Grid>

                <Grid item>
                  <FormControl className={classes.formControl}>
                    <InputLabel className={classes.label2} id="demo-mutiple-checkbox-label">Role</InputLabel>
                    <Select
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      value={user.role}
                      renderValue={selected => capitalize(selected)}
                      onChange={this.handleRole.bind(this)}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value={"therapist"}>
                        <Checkbox checked={user.role === 'therapist'} />
                        <ListItemText primary={"Therapist"} />
                      </MenuItem>
                      <MenuItem value={"doctor"}>
                        <Checkbox checked={user.role === 'doctor'} />
                        <ListItemText primary={"Doctor"} />
                      </MenuItem>
                      <MenuItem value={"participant"}>
                        <Checkbox checked={user.role === 'participant'} />
                        <ListItemText primary={"Participant"} />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item>
                  <FormControl className={classes.formControl}>
                    <InputLabel className={classes.label2} id="demo-mutiple-checkbox-label">Site(s)</InputLabel>
                    <Select
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      multiple
                      value={user.Sites.map(s => s.id)}
                      onChange={this.handleSiteChange.bind(this)}
                      input={<Input />}
                      renderValue={(selected) => selected.map(s => sites.find(site => s === site.id).name).join(', ')}
                      MenuProps={MenuProps}
                    >
                      {sites.map((site) => (
                        <MenuItem key={site.id} value={site.id}>
                          <Checkbox checked={user.Sites.map(s => s.id).indexOf(site.id) > -1} />
                          <ListItemText primary={site.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <Button onClick={this.submit.bind(this)}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
};

const styles = theme => ({
  eventWrapper: {
    marginTop: -24
  },
  formControl: {
    width: '100%'
  },
  teamWrapper: {
    background: globalColors.lightestGrey
  },
  widthWrapperFull: {
    width: '100%',
    ...globalStyles.widthWrapper(theme),
  },
  h2: {
    ...globalStyles.h2(theme),
    color: globalColors.blue
  },
  h3: {
    ...globalStyles.h3(theme),
  },
  label2: {
    ...globalStyles.label2
  },
  buttonBack: {
    ...globalStyles.button,
    color: globalColors.blue,
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: -4,
      left: -28,
      height: 24,
      width: 24,
      background: 'url(/icons/arrow-left.png)'
    }
  },
});

export default withRouter(withStyles(styles)(inject('userStore', 'siteStore')(observer(Home))));

// @flow
import axios from 'axios';
import config from '../config';

const GET = 'get';
const POST = 'post';
const DELETE = 'delete';
const PUT = 'put';

const initApi = () => {
  const api = axios.create();
  api.defaults.baseURL = `${config.apiURL}/superusers`;

  return api;
};

const axiosApi = initApi();

const getBody = (verb: string, body?: any): any => {
  // https://github.com/axios/axios/issues/897#issuecomment-343715381
  if (body !== undefined && verb === DELETE) {
    return { data: body };
  }

  return body;
};

const execute = async (verb: string, url: string, body?: any, additionalConfig: any) => {
  const config = {
    url,
    method: verb,
    data: getBody(verb, body),
    withCredentials: true,
    ...additionalConfig
  };
  const { status, headers, data } = await axiosApi.request(config);

  return {
    status,
    headers,
    data
  };
};

class SunstoneApi {

  async getAllTrials() {
    return execute(GET, '/trials');
  }

  async getTrial(trialId) {
    return execute(GET, `/trials/${trialId}`);
  }

  async getTrialEvents(trialId) {
    return execute(GET, `/trials/${trialId}/events`);
  }

  async removeTrialEnrollment(trialId, enrollmentId) {
    return execute(DELETE, `/trials/${trialId}/enrollment/${enrollmentId}`);
  }

  async createTrialEnrollment(trialId, enrollment) {
    return execute(POST, `/trials/${trialId}/enrollment`, enrollment);
  }

  async createTrialEvent(trialId, event) {
    return execute(POST, `/trials/${trialId}/events`, event);
  }

  async createTrialEnrollments(trialId, enrollments) {
    return execute(POST, `/trials/${trialId}/enrollments`, enrollments);
  }

  async createTrial(trial) {
    return execute(POST, '/trials', trial);
  }

  async getUsers() {
    return execute(GET, '/users');
  }

  async getUser(userId) {
    return execute(GET, `/users/${userId}`);
  }

  async updateUser(user) {
    return execute(PUT, `/users/${user.id}`, user);
  }

  async createUser(user) {
    return execute(POST, '/users', user);
  }

  async getSites() {
    return execute(GET, '/sites');
  }
}

export default new SunstoneApi();

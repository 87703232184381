import moment from 'moment';
import React from 'react';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const styles = theme => ({
  wrapper: {
    lineHeight: "24px",
    textAlign: "left",
  },
});

class NameFormatter extends React.PureComponent {
  render() {
    const {
      classes,
      wrapperClass,
      date,
    } = this.props;

    return (
      <Grid
        container
        direction="column"
        className={classNames(classes.wrapper, wrapperClass)}
      >
        <Grid item>
          {moment(date).format('M/D/YYYY')}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(NameFormatter);
